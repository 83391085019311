import { MDBCard, MDBCardHeader, MDBCardBody, MDBInput, MDBBtn, MDBValidation } from "mdb-react-ui-kit";
import { useState } from "react";
import Constants from "../../utilities/Constants";
import axios, { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";

export default function Login() {
    const navigate = useNavigate();
    const [formValue, setFormValue] = useState({
        username: "",
        password: "",
    });

    const handleSubmit = (event: any) => {
        event.preventDefault();
        axios
            .post(Constants.API_URL_POST_LOGIN, formValue)
            .then((response: AxiosResponse) => {
                localStorage.setItem("user", response.data);
                navigate("/admin/panel");
                window.location.reload();
            })
            .catch(function (error: any) {
                alert("Nieprawidłowe login lub hasło!");
            });
    }

    return (
        <MDBCard>
            <MDBCardHeader style={{ textAlign: "center" }}>
                <div className="row">
                    <div className="col-12">Logowanie | Miłego dnia :)</div>
                </div>
            </MDBCardHeader>
            <MDBCardBody>
                <MDBValidation id='loginForm' onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-4">
                            <MDBInput
                                className="m-2"
                                label='Login'
                                name="username"
                                onChange={(e) => { setFormValue({ ...formValue, [e.target.name]: e.target.value }); }}
                                type='text' />
                        </div>
                        <div className="col-md-4">
                            <MDBInput
                                className="m-2"
                                label='Hasło'
                                name="password"
                                onChange={(e) => { setFormValue({ ...formValue, [e.target.name]: e.target.value }); }}
                                type='password' />
                        </div>
                        <div className="col-md-4" style={{ textAlign: "center" }}>
                            <MDBBtn
                                block
                                style={{ width: "80%" }}
                                className="m-2"
                                form="loginForm"
                                color="success"
                                type="submit"
                            >
                                Zaloguj się</MDBBtn>
                        </div>
                    </div>
                </MDBValidation>
            </MDBCardBody >
        </MDBCard >
    );
}
