const API_BASE_URL = 'https://api.lekarzsiedlce.pl';

const ENDPOINTS = {
    GET_AVAILABLE: 'api/Reservation/available',
    GET_VALIDATE: 'api/Reservation/validate',
    POST_RESERVATION: 'api/Reservation',
    GET_CANCEL: 'api/Reservation/cancel',
    GET_CONFIRM: 'api/Reservation/confirm',
    GET_SYSTEM_STATUS: 'api/Reservation/status',
    POST_LOGIN: 'api/Reservation/login',

    PUT_STATUS: 'api/Reservation/visitors',
    GET_LOGIN: 'api/auth/AuthReservation/status',
}

const API = {
    API_URL_GET_AVAILABLE: `${API_BASE_URL}/${ENDPOINTS.GET_AVAILABLE}`,
    API_URL_GET_VALIDATE: `${API_BASE_URL}/${ENDPOINTS.GET_VALIDATE}`,
    API_URL_POST_RESERVATION: `${API_BASE_URL}/${ENDPOINTS.POST_RESERVATION}`,
    API_URL_GET_CANCEL: `${API_BASE_URL}/${ENDPOINTS.GET_CANCEL}`,
    API_URL_GET_CONFIRM: `${API_BASE_URL}/${ENDPOINTS.GET_CONFIRM}`,
    API_URL_GET_SYSTEM_STATUS: `${API_BASE_URL}/${ENDPOINTS.GET_SYSTEM_STATUS}`,
    API_URL_POST_LOGIN: `${API_BASE_URL}/${ENDPOINTS.POST_LOGIN}`,

    API_URL_PUT_STATUS: `${API_BASE_URL}/${ENDPOINTS.PUT_STATUS}`,
    API_URL_GET_LOGIN: `${API_BASE_URL}/${ENDPOINTS.GET_LOGIN}`,
}

const Constants = API;
export default Constants;
