const monthsName = ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];

export const niceDate = (selectedDate: string): string => {
  let data = new Date(selectedDate);
  return data.getDate() + ` ` + monthsName[data.getMonth()] + ` ` + data.getFullYear();
}

export const niceDateTime = (selectedDate: string): string => {
  let data = new Date(selectedDate);
  return data.getDate() + ` ` + monthsName[data.getMonth()] + ` ` + data.getFullYear() + ` ` + data.getHours() + `:` + (data.getMinutes() < 10 ? '0' : '') + data.getMinutes();
}

export const daysInMonth = (year: number, month: number): number => {
  return new Date(year, month, 0).getDate();
};

export const getMonthDiff = (startDate: Date, endDate: Date) => {
  return (
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear())
  );
};

export const getCurrentTime = () => {
  var date = new Date();
  return (
    date.getHours() + ':' + date.getMinutes() + ':00' 
  );
};

