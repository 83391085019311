import { IListElementProps, ITermListProps } from "../../global/admin/Interfaces";

const TermListElement = (props: IListElementProps): any => {
    let time = props.TermData.Time.slice(0, 5);
    if (time[0] == "0") time = time.slice(1);
    time += ` - ` + (Number(props.TermData.Time.slice(0, 2)) + (props.TermData.Duration == 30 ? 1 : 2)).toString() + props.TermData.Time.slice(2, 5);

    if(props.TermData.IsOccupied) { 
        if(props.TermData.Details) {
            return (
                <div onClick={() => props.handleDisplay(props.TermData.Details)}
                    className={`listElement ${props.TermData.Details?.IsConfirmed ?'busyTime':'blockedTime'}${props.isSelected ? ' busyBtn' : ''}`}
                    key={props.TermData.Time}>
                    <div className="row">
                        <div className="col-4">{time}</div>
                        <div className="col">{props.TermData.Details?.Name} {props.TermData.Details?.Surname}</div>
                    </div>
                </div>
            );
        }
        return (
            <div className='listElement reservedTime'
                key={props.TermData.Time}>
                <div className="row">
                    <div className="col-4">{time}</div>
                    <div className="col"></div>
                </div>
            </div>
        );
    } else {
        return (
            <div onClick={() => props.handleTime(props.TermData.Time)}
                className={`listElement availableTime${props.isSelected ? ' activeBtn' : ''}`}
                key={props.TermData.Time}>
                <div className="row">
                    <div className="col-4">{time}</div>
                    <div className="col"></div>
                </div>
            </div>
        );
    }
}

export default function TermList(props: ITermListProps) {
    const data = props.CalendarData;
    const result: any = [];

    data.Terms.forEach(item => {
        result.push(
            <TermListElement
                key={item.Time}
                TermData={item}
                handleTime={props.handleTime}
                handleDisplay={props.handleDisplay}
                isSelected={item.Time == props.selectedTime} />
        );
    });
    return result;
}
