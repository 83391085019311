import { useState, useEffect } from "react";
import Constants from "../../utilities/AdminConstants";
import { ICalendarView } from "../../global/Interfaces";
import CalendarView from "./CalendarView";
import axios, { AxiosResponse } from "axios";
import { ICalendarProps, IContainerProps } from "../../global/admin/Interfaces";

export default function CalendarContainer(props: IContainerProps) {
    const [calendarData, setCalendarData] = useState<ICalendarProps[]>([]);
    const [fetchedData, setFetchedData] = useState<ICalendarProps[]>([]);
    const [forceUpdate, setForceUpdate] = useState<number>(0);
    const [selectedView, setSelectedView] = useState<ICalendarView>({
        month: 1,
        day: 0,
    });

    useEffect(() => {
        if (forceUpdate == 30 || forceUpdate == 90) {
            props.handleReservation(forceUpdate);
        } else {
            fetchData();
        }
    }, [forceUpdate]);

    useEffect(() => {
        filterData();
    }, [props.fullView, props.visibleOnly, fetchedData])

    const filterData = () => {
        let tempData: ICalendarProps[] = [];
        let tempDate: ICalendarProps;

        fetchedData.forEach((dateItem) => {
            tempDate = {
                Date: dateItem.Date,
                Availables: dateItem.Availables,
                Terms: []
            }

            dateItem.Terms.forEach((termsItems) => {
                if ((props.fullView && props.visibleOnly) ||
                    (props.fullView && !props.visibleOnly && termsItems.IsOccupied == 1) ||
                    (!props.fullView && props.visibleOnly && termsItems.IsVisible == 1) ||
                    (!props.fullView && !props.visibleOnly && termsItems.IsOccupied == 1 && termsItems.IsVisible == 1)) 
                {
                    tempDate.Terms.push(termsItems);
                }
            });

            tempData.push(tempDate);
        });

        setCalendarData(tempData);
    }

    useEffect(() => {
        fetchData();
    }, [props.duration, selectedView.month]);

    const fetchData = async () => {debugger
        let currentDate = new Date();
        let sDate = new Date(currentDate.getFullYear() + `/` + (currentDate.getMonth() + 1) + `/01`);
        sDate.setMonth(sDate.getMonth() + selectedView.month - 1);
        sDate.setDate(sDate.getDate() - (sDate.getDay() == 0 ? 5 : sDate.getDay() - 2));

        const url = Constants.API_URL_GET_AVAILABLE;
        const data = {
            date: sDate.toISOString().split('T')[0],
            period: 41,
            duration: props.duration,
        }
        axios
            .get<ICalendarProps[]>(url + `?Date=${data.date}&Period=${data.period}&Duration=${data.duration}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('user')}`,
                }
            })
            .then((response: AxiosResponse) => {
                setFetchedData(Array(response.data)[0]);
            })
            .catch(function (error: any) {
                console.log(error);
            });
    }
    return (
        <div>
            {calendarData.length != 0 &&
                <CalendarView
                    CalendarData={calendarData}
                    selectedDuration={props.duration}
                    calendarView={selectedView}
                    handleView={setSelectedView}
                    forceUpdate={setForceUpdate}
                />}
        </div>
    );
}
