import { MDBCard, MDBCardBody, MDBCardHeader } from "mdb-react-ui-kit";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CalendarContainer from "../../components/timeOff/CalendarContainer";

export default function Wolne() {
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('user')?.length) {
            navigate("/admin/login");
            window.location.reload();
        }
    }, []);

    return (
        <>
            <MDBCard id='admin'>
                <MDBCardHeader style={{ textAlign: "center" }}>
                    <div className="row">
                        <div className="col-12">Dni wolne</div>
                    </div>
                </MDBCardHeader>
                <MDBCardBody>
                    <CalendarContainer/>
                </MDBCardBody >
            </MDBCard >
        </>
    );

}
