import { ITimeOffBodyProps } from "../../global/admin/Interfaces";
import CalendarDate from "./CalendarDate";

export default function CalendarBody(props: ITimeOffBodyProps) {
    const daysOfWeek = ['Pon', 'Wto', 'Śro', 'Czw', 'Pią', 'Sob', 'Nie'];
    let cells: any = [];

    props.CalendarData.forEach(item => {
        cells.push(
            <CalendarDate
                key={item.Date}
                CalendarData={item}
                isSelected={item.Date == props.selectedDate}
                handleList={props.handleList} />
        );
    });

    let result = cells;

    return (
        <>
            <div className="calendar-day-header">
                {daysOfWeek.map((item, i) => (
                    <span key={i} className="day-label">{item}</span>
                ))}
            </div>
            <hr style={{margin:"0 0 5px 0"}}/>
            <div className="calendar-grid">
                {result}
            </div>
        </>
    );
}
