import { MDBCard, MDBCardHeader, MDBCardBody } from "mdb-react-ui-kit";
import { Helmet } from "react-helmet-async";

export default function Kontakt() {
    return (
        <>
            <Helmet>
                <title>Kontakt</title>
                <meta name="title" content="Skontaktuj się z Homeopatycznym Gabinetem Lekarskim w Siedlcach" />
                <meta name="description" content="Masz pytania lub chcesz umówić wizytę? Zapraszam do kontaktu oraz rezerwacji wizyty online." />
                <meta name="keywords" content="kontakt, skontaktuj się, homeopatyczny gabinet lekarski, Siedlce, Małgorzata Grabowiec, pytania, rezerwacja wizyty online" />
                <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <MDBCard>
                <MDBCardHeader style={{ textAlign: "left" }}>
                    <div className="row">
                        <div className="col-4">Kontakt</div>
                        <div className="col-8"></div>
                    </div>
                </MDBCardHeader>
                <MDBCardBody>
                    <div className="row mb-5">
                        <div className="col-md-4" style={{ fontSize: "18px" }}>
                            <p style={{ fontWeight: "700" }}>Homeopatyczny Gabinet Lekarski <br /> lek. med. Małgorzata Grabowiec</p>
                            <p><strong>Adres:</strong>  <br /> ul.Teatralna 5/1, 08-110 Siedlce</p>
                            <p><strong>Kontakt:</strong> <br />
                                Tel.: <a href="tel:256440303" style={{ color: "black", textDecoration: "none" }}>25 644 03 03</a> <br />
                                Email: <a href="mailto:grabowiecmg@gmail.com" style={{ color: "black", textDecoration: "none" }}>grabowiecmg@gmail.com</a></p>
                        </div >
                        <div className="col-md-8" style={{ textAlign: "center" }}>
                            <img
                                style={{ marginTop: "50px" }}
                                src={require("../assets/images/Homeopatyczny-Gabinet-Lekarski.png")}
                                className="sectionImg"
                                alt="Homeopata Siedlce. Lekarz Siedlce." />
                        </div>
                    </div>
                    <div className="row" style={{ bottom: "0" }}>
                        <div className="col-12">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d611.7580663016647!2d22.280278229265853!3d52.170106821259644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471f5a529e7712b9%3A0xbaa788deb7d0988e!2sHomeopatyczny%20Gabinet%20Lekarski!5e0!3m2!1spl!2spl!4v1578076606415!5m2!1spl!2spl"
                                width="100%" height="300px"></iframe>
                        </div>
                    </div>
                </MDBCardBody >
            </MDBCard >
        </>
    );
}
