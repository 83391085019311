import { MDBBtn } from "mdb-react-ui-kit";
import { useState, useEffect } from "react";
import { getMonthDiff } from "../../global/Functions";
import CalendarBody from "./CalendarBody";
import TermList from "./TermList";
import { ITimeOffPost, ITimeOffProps, ITimeOffViewProps } from "../../global/admin/Interfaces";
import axios, { AxiosResponse } from "axios";
import Constants from "../../utilities/AdminConstants";

const findDefault = (data: ITimeOffProps[], selectedDay: number) => {
    let date = new Date(new Date(data[8].Date).getFullYear(), new Date(data[8].Date).getMonth(), 1);
    let dayNumber = date.getDay() == 0 ? 6 : date.getDay() - 1;

    if (selectedDay > 0) {
        let dataToReturn = data[selectedDay + dayNumber - 1];

        if (dataToReturn.Times.length) return dataToReturn;
    }

    let todayDate = new Date();
    let currentDate = date.getDate() + dayNumber;
    if (date.getMonth() == todayDate.getMonth() && date.getFullYear() == todayDate.getFullYear()) {
        currentDate = new Date().getDate() + dayNumber;
    }
    for (let i = -1; i < 8; i++) {
        if (data[currentDate + i].Times.length) return data[currentDate + i];
    }
    return data[8];
};

export default function CalendarView(props: ITimeOffViewProps) {
    const monthsName = ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];
    const currentView = new Date(props.CalendarData[20].Date);
    const [data, setData] = useState<ITimeOffProps[]>(props.CalendarData);
    const [currentPosition, setCurrentPosition] = useState<number>(() => {
        return currentView.getMonth() - new Date().getMonth();
    });
    const [currentDate, setCurrentDate] = useState<ITimeOffProps>(() => findDefault(props.CalendarData, props.calendarView.day));

    const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
    const [buttonStatus, setButtonStatus] = useState<boolean>(false);
    const [maxTimes, setMaxTimes] = useState<number>(0);

    const handlePosition = (count: number) => {
        if (count == 0) {
            setCurrentPosition(0);
        } else if (count == 1) {
            setCurrentPosition(currentPosition + 1);
        } else if (count == -1) {
            setCurrentPosition(currentPosition - 1);
        }
        setButtonStatus(false);
    };

    const findMax = (): number => {
        let length = 0;
        currentDate.Times.forEach(x => {
            if (x.IsOccupied != 2) length++;
        });
        return length ?? 6;
    };

    useEffect(() => {
        setData(props.CalendarData);

        let sameMonth = new Date(props.CalendarData[8].Date).getMonth() == new Date(currentDate.Date).getMonth();
        let tempData = findDefault(props.CalendarData, sameMonth ? new Date(currentDate.Date).getDate() : 0);
        setCurrentDate(tempData);

    }, [props.CalendarData]);

    useEffect(() => {
        props.handleView({
            month: currentPosition + 1,
            day: new Date(currentDate.Date).getDate(),
        });
    }, [currentPosition])

    useEffect(() => {
        if (getMonthDiff(new Date(), new Date(currentDate.Date)) <= 1 &&
            new Date(currentDate.Date).getMonth() != currentView.getMonth()) {
            handlePosition(getMonthDiff(currentView, new Date(currentDate.Date)));
        }
        setMaxTimes(findMax());
        setSelectedTimes([]);
    }, [currentDate]);

    const selectAll = () => {
        let result: string[] = [];
        if (selectedTimes.length != maxTimes) {
            currentDate.Times.forEach(e => {
                if (e.IsOccupied != 2) result.push(e.Time);
            });
            setSelectedTimes(result);
        } else {
            setSelectedTimes([]);
        }
    }

    const selectTime = (selectedTime: string) => {
        if (selectedTimes.includes(selectedTime)) {
            let index = selectedTimes.indexOf(selectedTime);
            setSelectedTimes([...selectedTimes.slice(0, index), ...selectedTimes.slice(index + 1)])
        } else {
            setSelectedTimes([...selectedTimes, selectedTime]);
        }
    }

    const updateTimes = (type: boolean) => {
        const url = Constants.API_URL_POST_TIMEOFF;
        const data: ITimeOffPost = {
            date: currentDate.Date,
            dateTime: []
        }
        currentDate.Times.forEach(x => {
            if(x.IsOccupied == 1 && !selectedTimes.includes(x.Time)) {
                data.dateTime.push(data.date + "T" + x.Time);
            }
        }); 

        if(!type) {
            selectedTimes.forEach(x => {
                data.dateTime.push(data.date + "T" + x);
            });
        }
    
        axios
            .post(url, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('user')}`,
                }
            })
            .then((response: AxiosResponse) => {
                props.forceUpdate(100 + Math.random() * (999999));
                setSelectedTimes([]);
            })
            .catch(function (error: any) {
                console.log(error);
            });
    }

    return (
        <>
            <div className="app-container row">
                <div className="datepicker col-md-6">
                    <div className="datepicker-calendar">
                        <div className="calendar-header">
                            <div className="goback" onClick={() => handlePosition(-1)}>
                                <svg width="30" height="30">
                                    <path fill="none" stroke="#0DAD83" strokeWidth="3" d="M19,6 l-9,9 l9,9" />
                                </svg>
                            </div>
                            <div className="current-month-container" onClick={() => handlePosition(0)}>{currentView.getFullYear()} {monthsName[currentView.getMonth()]}</div>
                            <div className="goforward" onClick={() => handlePosition(1)}>
                                <svg width="30" height="30">
                                    <path fill="none" stroke="#0DAD83" strokeWidth="3" d="M11,6 l9,9 l-9,9" />
                                </svg>
                            </div>
                        </div>

                        {data.length != 0 && <CalendarBody
                            CalendarData={data}
                            selectedDate={currentDate.Date}
                            handleList={setCurrentDate}
                        />}

                    </div>
                    <div style={{ textAlign: "center" }}>
                        <div className="row">
                            <div className="col-12">
                                <MDBBtn
                                    style={{ marginTop: "20px" }}
                                    color="primary"
                                    className="col-8"
                                    onClick={() => selectAll()}
                                >{selectedTimes.length == maxTimes ? `Odznacz wszystko` : `Zaznacz wszystko`}
                                </MDBBtn>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <MDBBtn
                                    style={{ marginTop: "20px" }}
                                    color="danger"
                                    className="col-11"
                                    disabled={selectedTimes.length == 0}
                                    onClick={() => updateTimes(false)}
                                >Niedostępne
                                </MDBBtn>
                            </div>
                            <div className="col-6">
                                <MDBBtn
                                    style={{ marginTop: "20px" }}
                                    color="success"
                                    className="col-11"
                                    disabled={selectedTimes.length == 0}
                                    onClick={() => updateTimes(true)}
                                >Dostępne
                                </MDBBtn>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="timepicker col-md-6">
                    <div className="timepicker-container-outer">
                        <div className="timepicker-container-inner">
                            {currentDate.Times.length ? (
                                <TermList
                                    CalendarData={currentDate}
                                    selectedTime={selectedTimes}
                                    handleTime={selectTime}
                                />
                            ) : (
                                <div className="row" style={{ textAlign: "center", marginTop: "50px" }}>
                                    <h4>Brak dostępnych terminów.</h4>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
