import { ICalendarDateProps } from "../../global/Interfaces";

export default function CalendarDate(props: ICalendarDateProps) {
    let data = props.CalendarData;
    let currentDate = new Date(data.Date);

    return (
        <div className={"calendarDate datecontainer"}
            onClick={(e) => data.Availables == 0 ? e.preventDefault() : props.handleList(data)}
            key={data.Date}>
            <div className={(props.isSelected ? 'day-selected ' : '') + "datenumber" +
                (props.CalendarData.Availables == 0 ? ' notAvailableDate' : ' availableDate')}>
                {currentDate.getDate()}
            </div>
        </div>
    );
}
