import { MDBCard, MDBCardBody, MDBCardHeader, MDBCheckbox } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CalendarContainer from "../../components/adminView/CalendarContainer";
import axios, { AxiosResponse } from "axios";
import Constants from "../../utilities/Constants";

export default function Panel() {
    const navigate = useNavigate();
    const [displayStatus, setDisplayStatus] = useState<number>(0);
    const [duration, setDuration] = useState<number>(30);
    const [fullView, setFullView] = useState<boolean>(false);
    const [visibleOnly, setVisibleOnly] = useState<boolean>(true);
    const [rC, setReservationCounter] = useState<number>(0);

    useEffect(() => {
        axios
            .get(Constants.API_URL_GET_LOGIN, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('user')}`,
                }
            })
            .then((response: AxiosResponse) => {
                setReservationCounter(response.data);
            })
    }, []);

    return (
        <>
            <MDBCard id='admin'>
                <MDBCardHeader style={{ textAlign: "center" }}>
                    <div className="row">
                        <div className="col-12">Rezerwacje wizyt<span style={{float:"right", color:rC>4?"#e23011":rC!=0?"#38A853":"#000"}}>{rC}</span></div>
                    </div>
                </MDBCardHeader>
                <MDBCardBody>
                    <div className="row">
                        <div className="col-md-6" id='durationSection'>
                            <div role="radiogroup" className="radio-group" aria-labelledby="group_heading">
                                <input type="radio" id='durationLong' name='durationSelect' onClick={() => setDuration(90)} />
                                <label className="radio-label" htmlFor='durationLong' style={{ padding: "12px" }}>
                                    <h4>Pierwsza wizyta</h4>
                                </label>
                                <input type="radio" id='durationShort' name='durationSelect' onClick={() => setDuration(30)} defaultChecked />
                                <label className="radio-label" htmlFor='durationShort' style={{ padding: "12px" }}>
                                    <h4>Kolejna wizyta</h4>
                                </label>
                            </div>
                        </div>
                        <hr className="d-md-none" style={{ margin: "15px 0 15px 0" }} />
                        <div className="col-md-6 d-flex justify-content-center align-items-center">
                            <MDBCheckbox
                                name='flexCheck'
                                value=''
                                id='fullViewCheckbox'
                                onChange={() => setFullView(!fullView)}
                                label='Godziny niestandardowe'
                                defaultChecked={fullView} inline />
                            <MDBCheckbox
                                name='flexCheck'
                                value=''
                                id='visibleOnlyCheckbox'
                                onChange={() => setVisibleOnly(!visibleOnly)}
                                label='Dostępne terminy'
                                defaultChecked={visibleOnly} inline />
                        </div>
                    </div>
                    <hr />
                    <CalendarContainer fullView={fullView} visibleOnly={visibleOnly} duration={duration} handleReservation={setDisplayStatus} />
                </MDBCardBody >
            </MDBCard >
        </>
    );
}
