import { ITimeOffDateProps } from "../../global/admin/Interfaces";

export default function CalendarDate(props: ITimeOffDateProps) {
    let data = props.CalendarData;
    let currentDate = new Date(data.Date);

    return (
        <div className={"calendarDate datecontainer"}
            onClick={(e) => data.Times.length == 0 ? e.preventDefault() : props.handleList(data)}
            key={data.Date}>
            <div className={(props.isSelected ? 'day-selected ' : '') + "datenumber" +
                (props.CalendarData.Times.length == 0 ? ' notAvailableDate' : ' availableDate')}>
                {currentDate.getDate()}
            </div>
        </div>
    );
}
