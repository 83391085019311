import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './assets/styles/global.css';
import './assets/styles/adminView.css';
import axios, { AxiosResponse } from 'axios';
import { MDBNavbar, MDBContainer, MDBNavbarBrand, MDBNavbarToggler, MDBIcon, MDBCollapse, MDBNavbarNav, MDBNavbarItem, MDBNavbarLink, MDBInputGroup, MDBBtn } from 'mdb-react-ui-kit';
import { useState, useEffect } from 'react';
import { useNavigate, Outlet, BrowserRouter, Routes, Route, Router } from 'react-router-dom';
import Index from './pages';
import Login from './pages/admin/login';
import Panel from './pages/admin/panel';
import Rezerwacje from './pages/admin/rezerwacje';
import Ustawienia from './pages/admin/ustawienia';
import Wolne from './pages/admin/wolne';
import AnulujRezerwacje from './pages/anuluj-rezerwacje';
import Kontakt from './pages/kontakt';
import PotwierdzRezerwacje from './pages/potwierdz-rezerwacje';
import Przypadki from './pages/przypadki';
import Pytania from './pages/pytania';
import RezerwacjaWizyty from './pages/rezerwacja-wizyty';
import Constants from './utilities/Constants';
import { HelmetProvider } from 'react-helmet-async';
import ReactDOM from 'react-dom/client';
import ReactGa from 'react-ga';

export default function App() {
  const navigate = useNavigate();
  const [showNav, setShowNav] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<string>(localStorage.getItem('user') ?? "");

  const TRACKING_ID = "UA-156033422-1";
  ReactGa.initialize(TRACKING_ID);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user !== null) {
      setCurrentUser(user);
    }

    if (!currentUser.length && !window.location.pathname.includes("/admin/")) {
      const sessionJson = localStorage.getItem('session');
      const blogSessionJson = localStorage.getItem('blogSession');

      if (window.location.pathname == "/przypadki") {
        if (blogSessionJson == null ||
          Math.floor(Date.now() / 1000) - parseInt(JSON.parse(blogSessionJson)) > 21600) {
          localStorage.setItem('blogSession', Math.floor(Date.now() / 1000).toString());

          var count = 0, timer = setInterval(function () {
            count++;

            if (count == 29) {
              const data = { "name": 'blog' };
              axios.put(Constants.API_URL_PUT_STATUS, data);
              clearInterval(timer);
            }
          }, 180000);
        }
      } else {
        if (sessionJson == null ||
          Math.floor(Date.now() / 1000) - parseInt(JSON.parse(sessionJson)) > 21600) {
          localStorage.setItem('session', Math.floor(Date.now() / 1000).toString());

          const data = { "name": 'index' };
          axios.put(Constants.API_URL_PUT_STATUS, data);
        }
      }
    } else if (window.location.pathname.includes("/admin/") && !window.location.pathname.includes("/admin/login")) {
      if (localStorage.getItem('user') != null) {
        axios
          .get(Constants.API_URL_GET_LOGIN, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('user')}`,
            }
          })
          .catch(function (error: any) {
            if (error.response.status == 401) {
              localStorage.clear();
              navigate('/admin/login', { replace: true });
              window.location.reload();
            }
          });
      } else {
        localStorage.clear();
        navigate('/admin/login', { replace: true });
        window.location.reload();
      }
    } else if (currentUser.length && window.location.pathname.includes("/admin/login")) {
      axios
        .get(Constants.API_URL_GET_LOGIN, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('user')}`,
          }
        })
        .then((response: AxiosResponse) => {
          navigate('/admin/panel', { replace: true });
          window.location.reload();
        })
    }
  }, []);

  const LogoutHandle = () => {
    localStorage.clear();
    navigate('/admin/login', { replace: true });
    window.location.reload();
  }

  useEffect(() => {
    ReactGa.pageview(window.location.pathname);
  }, []);

  const Layout = () => {
    return (
      <>
        <MDBNavbar expand='lg' dark>
          <MDBContainer fluid>
            <MDBNavbarBrand href='/'>
              <img
                src={require('./assets/images/homeopatyczny-gabinet-lekarski-siedlce-logo.png')}
                height='60'
                alt=''
                loading='lazy'
              />
            </MDBNavbarBrand>
            <MDBNavbarToggler
              type='button'
              aria-expanded='false'
              aria-label='Toggle navigation'
              onClick={() => setShowNav(!showNav)}
            >
              <MDBIcon icon='bars' fas />
            </MDBNavbarToggler>
            <MDBCollapse navbar show={showNav}>
              {currentUser.length && window.location.pathname.includes("/admin/") ? (
                <>
                  <MDBNavbarNav>
                    <MDBNavbarItem>
                      <MDBNavbarLink href='/admin/panel' active={window.location.pathname == "/admin/panel"}>Panel główny</MDBNavbarLink>
                    </MDBNavbarItem>
                    <MDBNavbarItem>
                      <MDBNavbarLink href='/admin/rezerwacje' active={window.location.pathname == "/admin/rezerwacje"}>Rezerwacje</MDBNavbarLink>
                    </MDBNavbarItem>
                    <MDBNavbarItem>
                      <MDBNavbarLink href='/admin/wolne' active={window.location.pathname == "/admin/wolne"}>Dni wolne</MDBNavbarLink>
                    </MDBNavbarItem>
                  </MDBNavbarNav>

                  <MDBNavbarItem className='d-flex w-auto form-inline'>
                    <MDBNavbarLink href='/admin/ustawienia' active={window.location.pathname == "/admin/ustawienia"}>Ustawienia</MDBNavbarLink>
                  </MDBNavbarItem>
                  <MDBNavbarItem className='d-flex w-auto form-inline'>
                    <MDBNavbarLink onClick={LogoutHandle}>Wyloguj</MDBNavbarLink>
                  </MDBNavbarItem>
                </>
              ) : (
                <>
                  <MDBNavbarNav>
                    <MDBNavbarItem>
                      <MDBNavbarLink href='/' active={window.location.pathname == "/"}>
                        <i className="fas fa-home"></i> Strona główna
                      </MDBNavbarLink>
                    </MDBNavbarItem>
                    <MDBNavbarItem>
                      <MDBNavbarLink href='/przypadki' active={window.location.pathname == "/przypadki"}>
                        <i className="fas fa-notes-medical"></i> Przypadki
                      </MDBNavbarLink>
                    </MDBNavbarItem>
                    <MDBNavbarItem>
                      <MDBNavbarLink href='/pytania' active={window.location.pathname == "/pytania"}>
                        <i className="fas fa-question"></i> Najczęstrze pytania
                      </MDBNavbarLink>
                    </MDBNavbarItem>
                    <MDBNavbarItem>
                      <MDBNavbarLink href='/kontakt' active={window.location.pathname == "/kontakt"}>
                        <i className="fas fa-phone"></i> Kontakt
                      </MDBNavbarLink>
                    </MDBNavbarItem>
                  </MDBNavbarNav>
                  <MDBInputGroup tag="form" className='d-flex w-auto form-inline'>
                    <MDBBtn id='reservationBtn' href='/rezerwacja-wizyty' active={window.location.pathname == "/rezerwacja-wizyty"} size="lg" color="white" outline>Rezerwacja wizyty</MDBBtn>
                  </MDBInputGroup>
                </>
              )}
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>

        <MDBContainer style={{ marginBottom: "25px" }} fluid>
          <Outlet />
        </MDBContainer>
      </>
    )
  };

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Index />} />
        <Route path="/rezerwacja-wizyty" element={<RezerwacjaWizyty />} />
        <Route path="/anuluj" element={<AnulujRezerwacje />} />
        <Route path="/potwierdz" element={<PotwierdzRezerwacje />} />
        <Route path="/przypadki" element={<Przypadki />} />
        <Route path="/pytania" element={<Pytania />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/admin/login" element={<Login />} />
        {currentUser.length && (
          <>
            <Route path="admin/panel" element={<Panel />} />
            <Route path="admin/rezerwacje" element={<Rezerwacje />} />
            <Route path="admin/wolne" element={<Wolne />} />
            <Route path="admin/ustawienia" element={<Ustawienia />} />
          </>
        )}
      </Route>
    </Routes>
  );
}

const HighApp = () => (<HelmetProvider><BrowserRouter><App /></BrowserRouter></HelmetProvider>);
const rootElement = document.getElementById("root-container");

if (rootElement && rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(rootElement as HTMLElement, <HighApp />);
} else {
  const root = ReactDOM.createRoot(rootElement as HTMLElement);
  root.render(<HighApp />);
}
