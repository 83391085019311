import { MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalBody, MDBModalFooter } from "mdb-react-ui-kit";
import { IModalInfoProps } from "../../global/Interfaces";

export default function InfoModal(props: IModalInfoProps) {
    return (
        <>
            <MDBModal
                id="infoModal"
                show={props.isActive}
                onHide={() => props.handleModal()}
                staticBackdrop={true}
                tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalBody>
                            <div>
                                <h5>{props.text}</h5>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter className="d-inline-block justify-content-between">
                            <div className="row">
                                <MDBBtn block onClick={() => props.handleModal()} color="primary">Zamknij</MDBBtn>
                            </div>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}
