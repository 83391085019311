import { MDBCard, MDBCardHeader, MDBCardBody, MDBContainer, MDBBtn, MDBCardImage, MDBCardText, MDBCardTitle, MDBRipple, MDBInput, MDBCardFooter } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import '../assets/styles/cases.css';
import { casesData } from "../assets/data/cases";
import { ICases } from "../global/Interfaces";
import { Helmet } from "react-helmet-async";

export default function Index() {
    const [casesList, setCasesList] = useState<ICases[]>(casesData);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [style, setStyle] = useState<any>(() => {
        let value = [];
        for (let i = 0; i < casesData.length; i++)  value[i] = false;
        return value;
    });

    useEffect(() => {
        if (searchQuery.length > 1) {
            let filteredList = casesList.filter(e => e.title.toLowerCase().includes(searchQuery.toLowerCase()));
            if (filteredList.length > 0) setCasesList(filteredList);
            else setCasesList(casesData);
        } else {
            setCasesList(casesData);
        }
    }, [searchQuery]);

    return (
        <>
            <Helmet>
                <title>Przypadki medyczne</title>
                <meta name="title" content="Studia przypadków medycznych | Homeopatyczny Gabient Lekarski w Siedlcach" />
                <meta name="description" content="Dowiedz się, jak homeopatia pomogła moim pacjentom w różnorodnych przypadkach zdrowotnych. Poznaj inspirujące historie powrotu do zdrowia dzięki homeopatii." />
                <meta name="keywords" content="przypadki medyczne, studia przypadków, medycyna homeopatyczna, leczenie, powrót do zdrowia, homeopatyczny gabinet lekarski, Siedlce" />
                <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <MDBCard>
                <MDBCardHeader>
                    <div className="row">
                        <div className="col-8">Opisy przypadków</div>
                        <div className="col-4" style={{ fontWeight: "400", fontSize: "18px" }}>
                            <MDBInput label='Szukaj..' onChange={(e) => setSearchQuery(e.target.value)} type='text' />
                        </div>
                    </div>
                </MDBCardHeader>
            </MDBCard>
            <MDBContainer fluid className="casesContainer">
                {casesList.map((data, key) => {
                    return (
                        <MDBCard key={key} className={"casesCard" + (style[key] ? ' casesCardExpanded' : '')}>
                            <MDBRipple
                                onClick={() => { style[key] = !style[key]; setStyle({ ...style }); }}
                                rippleColor='light'
                                rippleTag='div'
                                className='bg-image hover-overlay casesRipple'
                            >
                                <MDBCardImage
                                    className='casesImg'
                                    src={require('../assets/images/sample/' + data.image)}
                                    alt={data.alt} fluid />
                                <a>
                                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                </a>
                            </MDBRipple>
                            <MDBCardBody className={"casesBody" + (style[key] ? ' casesBodyExpanded' : '')}>
                                <MDBCardTitle style={{ fontWeight: "600", fontSize: "22px" }}>{data.title}</MDBCardTitle>
                                <MDBCardText>{data.text}</MDBCardText>
                            </MDBCardBody>
                            <MDBCardFooter className="casesFooter">
                                <MDBBtn className="casesBtn" onClick={() => { style[key] = !style[key]; setStyle({ ...style }); }}>Czytaj dalej</MDBBtn>
                            </MDBCardFooter>
                        </MDBCard>
                    );
                })}
            </MDBContainer>
        </>
    );
}
