import { MDBCard, MDBCardHeader, MDBCardBody, MDBAccordion, MDBAccordionItem, MDBIcon } from "mdb-react-ui-kit";
import { Helmet } from "react-helmet-async";

export default function Index() {
    return (
        <>
            <Helmet>
                <title>Najczęstsze pytania</title>
                <meta name="title" content="Najczęściej Zadawane Pytania | Homeopatyczny Gabinet Lekarski" />
                <meta name="description" content="Poznaj odpowiedzi na najczęściej zadawane pytania dotyczące homeopatii oraz rezerwacji wizyt. " />
                <meta name="keywords" content="najczęstsze pytania, homeopatia, medycyna homeopatyczna, leczenie, pytania i odpowiedzi, terapia, rezerwacja wizyty online" />
                <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <MDBCard>
                <MDBCardHeader>Pytania i Odpowiedzi</MDBCardHeader>
                <MDBCardBody className="faqBody">
                    <MDBAccordion initialActive={1}>
                        <MDBAccordionItem collapseId={1} headerTitle={<><MDBIcon fas icon="question-circle" /> &nbsp; Czy można stosować leki homeopatyczne z innym lekami?</>}>
                            <strong>Tak.</strong>
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={2} headerTitle={<><MDBIcon fas icon="question-circle" /> &nbsp; Czy są ograniczenia wiekowe co do leczenia?</>}>
                            <strong>Nie.</strong> Można leczyć zarówno dzieci jak i dorosłych w każdym wieku.
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={3} headerTitle={<><MDBIcon fas icon="question-circle" /> &nbsp; Czy mogą być skutki uboczne leczenia homeopatycznego?</>}>
                            <strong>Nie.</strong> Leki są bezpieczne nie wywołują skutków ubocznych,
                            czasami mogą być reakcje zaostrzenia objawów wówczas należy skonsultować to z lekarzem, który wytłumaczy pacjentowi co się dzieje.
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={4} headerTitle={<><MDBIcon fas icon="question-circle" /> &nbsp; Gdzie można kupić leki homeopatyczne?</>}>
                            Leki homeopatyczne dostępne są tak jak wszystkie inne leki, w aptekach, w Siedlcach są to:
                            <ol>
                                <li>Apteka Zdrowie ul. Świętojańska 4</li>
                                <li>Apteka DOZ ul. Floriańska 20</li>
                                <li>Apteka DOZ ul. Unitów Podlaskich 11</li>
                            </ol>
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={5} headerTitle={<><MDBIcon fas icon="question-circle" /> &nbsp; Czy możliwa jest pomoc dziecku z autyzmem lub Aspergerem?</>}>
                            <strong>Tak.</strong> Istnieją leki homeopatyczne poprawiające funkcjonowanie dzieci z tymi chorobami.
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={6} headerTitle={<><MDBIcon fas icon="question-circle" /> &nbsp; Czy można przyjmować dwa leki homeopatyczne jednocześnie?</>}>
                            <strong>Nie.</strong> Leki przyjmujemy oddzielnie, w odstępach minimum 15min.
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={7} headerTitle={<><MDBIcon fas icon="question-circle" /> &nbsp; Czy można popijać leki?</>}>
                            <strong>Tak.</strong> Leki homeopatyczne można popijać jedynie przegotowaną wodą, ale najlepiej jest ssać.
                        </MDBAccordionItem>
                    </MDBAccordion >
                </MDBCardBody >
            </MDBCard >
        </>
    );
}
