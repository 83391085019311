import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCheckbox, MDBIcon, MDBInputGroup, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Constants from "../../utilities/AdminConstants";
import { IConfiguration, ReservationStats } from "../../global/admin/Interfaces";
import axios, { AxiosResponse } from "axios";

export default function Ustawienia() {
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('user')?.length) {
            navigate("/admin/login");
            window.location.reload();
        }
    }, []);

    const [configuration, setConfiguration] = useState<IConfiguration>({
        id: 0,
        isReservationOn: 0,
        longPrice: 0,
        longTime: 0,
        shortPrice: 0,
        shortTime: 0,
        visitorYesterdayDate: new Date(),
        visitorToday: 0,
        visitorYesterday: 0,
        visitorAll: 0,
        visitorBlogYesterdayDate: new Date(),
        visitorBlogToday: 0,
        visitorBlogYesterday: 0,
        visitorBlogAll: 0
    });

    const [stats, setStats] = useState<ReservationStats>({
        globalRes: {
            first: 0,
            firstComp: 0,
            second: 0,
            secondComp: 0,
            third: 0,
            thirdComp: 0
        },
        onlineRes: {
            first: 0,
            firstComp: 0,
            second: 0,
            secondComp: 0,
            third: 0,
            thirdComp: 0
        },
        newRes: {
            first: 0,
            firstComp: 0,
            second: 0,
            secondComp: 0,
            third: 0,
            thirdComp: 0
        }
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        axios
            .get<IConfiguration>(Constants.API_URL_GET_CONFIGURATION, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('user')}`,
                }
            })
            .then((response: AxiosResponse) => {
                setConfiguration(response.data);
            })
            .catch(function (error: any) {
                console.log(error);
            });


        axios
            .get<ReservationStats>(Constants.API_URL_GET_STATISTICS, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('user')}`,
                }
            })
            .then((response: AxiosResponse) => {
                setStats(response.data);
            })
            .catch(function (error: any) {
                console.log(error);
            });
    }

    const updatePrice = () => {
        const url = Constants.API_URL_PUT_CONFIGURATION;
        const data = {
            isReservationOn: configuration.isReservationOn,
            longPrice: configuration.longPrice,
            longTime: configuration.longTime,
            shortPrice: configuration.shortPrice,
            shortTime: configuration.shortTime,
        }
        axios
            .put(url, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('user')}`,
                }
            })
            .then((response: AxiosResponse) => {

            })
            .catch(function (error: any) {
                console.log(error.response);

            });
    }

    const onChange = (e: any) => {
        if (e.target.name == 'isReservationOn') {
            setConfiguration({ ...configuration, [e.target.name]: e.target.checked ? 1 : 0});
        } else {
            setConfiguration({ ...configuration, [e.target.name]: parseInt(e.target.value) });
        }
    };

    return (
        <>
            <MDBCard>
                <MDBCardHeader style={{ textAlign: "center" }}>
                    <div className="row">
                        <div className="col-12">Ustawienia i statystyki</div>
                    </div>
                </MDBCardHeader>
                <MDBCardBody>
                    <div className="row">
                        <div className="col-md-12">
                            <MDBInputGroup
                                style={{ background: "white" }}
                                className='mb-3'
                                textBefore='Pierwsza wizyta'>
                                <input className='form-control' placeholder="Koszt wizyty" type='number' min={0}
                                    name="longPrice"
                                    value={configuration.longPrice}
                                    onChange={onChange} />
                                <span className='input-group-text'>PLN</span>
                                <input className='form-control' placeholder="Czas trwania" type='number' min={0}
                                    name="longTime"
                                    value={configuration.longTime}
                                    onChange={onChange} />
                                <span className='input-group-text'>min</span>
                            </MDBInputGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <MDBInputGroup
                                style={{ background: "white" }}
                                className='mb-3'
                                textBefore='Kolejna wizyta '>
                                <input className='form-control' placeholder="Koszt wizyty" type='number' min={0}
                                    name="shortPrice"
                                    value={configuration.shortPrice}
                                    onChange={onChange} />
                                <span className='input-group-text'>PLN</span>
                                <input className='form-control' placeholder="Czas trwania" type='number' min={0}
                                    name="shortTime"
                                    value={configuration.shortTime}
                                    onChange={onChange} />
                                <span className='input-group-text'>min</span>
                            </MDBInputGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <MDBInputGroup
                                className='mb-3'
                                textTag='div'
                                textBefore={
                                    <MDBCheckbox
                                        name="isReservationOn"
                                        checked={configuration.isReservationOn ? true : false}
                                        onChange={onChange}
                                    />}>
                                <span className='input-group-text'>Status dostępności rezerwacji</span>
                                <MDBBtn onClick={updatePrice} outline>Zaktualizuj wszystkie dane</MDBBtn>
                            </MDBInputGroup>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col">
                            <MDBTable striped>
                                <MDBTableHead>
                                    <tr>
                                        <th scope='col' style={{ width: "25%" }}>Strona</th>
                                        <th scope='col' style={{ width: "25%" }}>Dzisiaj</th>
                                        <th scope='col' style={{ width: "25%" }}>Wczoraj</th>
                                        <th scope='col' style={{ width: "25%" }}>Wszystkie</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    <tr>
                                        <th scope='row'>Odwiedziny ogólne</th>
                                        <td>{configuration.visitorToday}</td>
                                        <td>{configuration.visitorYesterday}</td>
                                        <td>{configuration.visitorAll}</td>
                                    </tr>
                                    <tr>
                                        <th scope='row'>Odwiedziny bloga</th>
                                        <td>{configuration.visitorBlogToday}</td>
                                        <td>{configuration.visitorBlogYesterday}</td>
                                        <td>{configuration.visitorBlogAll}</td>
                                    </tr>
                                </MDBTableBody>
                            </MDBTable>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col">
                            <MDBTable striped>
                                <MDBTableHead>
                                    <tr>
                                        <th scope='col' style={{ width: "25%" }}>Rodzaj rezerwacji</th>
                                        <th scope='col' style={{ width: "25%" }}>Aktualny tydzień</th>
                                        <th scope='col' style={{ width: "25%" }}>Aktualny miesiąc</th>
                                        <th scope='col' style={{ width: "25%" }}>Aktualny rok</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    <tr>
                                        <th scope='row'>Wszystkie rezerwacje</th>
                                        {
                                            (stats.globalRes.first / stats.globalRes.firstComp - 1 >= 0) ?
                                                <td className='text-success statsStyle'>
                                                    <MDBIcon className='me-1' fas icon='caret-up' />
                                                    {Math.round(stats.globalRes.first / (stats.globalRes.firstComp - 1 == 0 ? stats.globalRes.firstComp - 1 : 1)) * 100}%
                                                    <span>({stats.globalRes.first}/{stats.globalRes.firstComp})</span>
                                                </td>
                                                :
                                                <td className='text-danger statsStyle'>
                                                    <MDBIcon className='me-1' fas icon='caret-down' />
                                                    {Math.round((1 - stats.globalRes.first / stats.globalRes.firstComp) * 10) * 10}%
                                                    <span>({stats.globalRes.first}/{stats.globalRes.firstComp})</span>
                                                </td>
                                        }
                                        {
                                            (stats.globalRes.second / stats.globalRes.secondComp - 1 >= 0) ?
                                                <td className='text-success statsStyle'>
                                                    <MDBIcon className='me-1' fas icon='caret-up' />
                                                    {(stats.globalRes.second / (stats.globalRes.second - 1 == 0 ? stats.globalRes.second - 1 : 1)) * 100}%
                                                    <span>({stats.globalRes.second}/{stats.globalRes.secondComp})</span>
                                                </td>
                                                :
                                                <td className='text-danger statsStyle'>
                                                    <MDBIcon className='me-1' fas icon='caret-down' />
                                                    {Math.round((1 - stats.globalRes.second / stats.globalRes.secondComp) * 10) * 10}%
                                                    <span>({stats.globalRes.second}/{stats.globalRes.secondComp})</span>
                                                </td>
                                        }
                                        {
                                            (stats.globalRes.third / stats.globalRes.thirdComp - 1 >= 0) ?
                                                <td className='text-success statsStyle'>
                                                    <MDBIcon className='me-1' fas icon='caret-up' />
                                                    {(stats.globalRes.third / (stats.globalRes.third - 1 == 0 ? stats.globalRes.third - 1 : 1)) * 100}%
                                                    <span>({stats.globalRes.third}/{stats.globalRes.thirdComp})</span>
                                                </td>
                                                :
                                                <td className='text-danger statsStyle'>
                                                    <MDBIcon className='me-1' fas icon='caret-down' />
                                                    {Math.round((1 - stats.globalRes.third / stats.globalRes.thirdComp) * 10) * 10}%
                                                    <span>({stats.globalRes.third}/{stats.globalRes.thirdComp})</span>
                                                </td>
                                        }
                                    </tr>
                                    <tr>
                                        <th scope='row'>Rezerwacje online</th>
                                        {
                                            (stats.onlineRes.first / stats.onlineRes.firstComp - 1 >= 0) ?
                                                <td className='text-success statsStyle'>
                                                    <MDBIcon className='me-1' fas icon='caret-up' />
                                                    {Math.round(stats.onlineRes.first / (stats.onlineRes.first - 1 == 0 ? stats.onlineRes.first - 1 : 1)) * 100}%
                                                    <span>({stats.onlineRes.first}/{stats.onlineRes.firstComp})</span>
                                                </td>
                                                :
                                                <td className='text-danger statsStyle'>
                                                    <MDBIcon className='me-1' fas icon='caret-down' />
                                                    {Math.round((1 - stats.onlineRes.first / stats.onlineRes.firstComp) * 10) * 10}%
                                                    <span>({stats.onlineRes.first}/{stats.onlineRes.firstComp})</span>
                                                </td>
                                        }
                                        {
                                            (stats.onlineRes.second / stats.onlineRes.secondComp - 1 >= 0) ?
                                                <td className='text-success statsStyle'>
                                                    <MDBIcon className='me-1' fas icon='caret-up' />
                                                    {(stats.onlineRes.second / (stats.onlineRes.second - 1 == 0 ? stats.onlineRes.second - 1 : 1)) * 100}%
                                                    <span>({stats.onlineRes.second}/{stats.onlineRes.secondComp})</span>
                                                </td>
                                                :
                                                <td className='text-danger statsStyle'>
                                                    <MDBIcon className='me-1' fas icon='caret-down' />
                                                    {Math.round((1 - stats.onlineRes.second / stats.onlineRes.secondComp) * 10) * 10}%
                                                    <span>({stats.onlineRes.second}/{stats.onlineRes.secondComp})</span>
                                                </td>
                                        }
                                        {
                                            (stats.onlineRes.third / stats.onlineRes.thirdComp - 1 >= 0) ?
                                                <td className='text-success statsStyle'>
                                                    <MDBIcon className='me-1' fas icon='caret-up' />
                                                    {(stats.onlineRes.third / (stats.onlineRes.third  - 1 == 0 ? stats.onlineRes.third  - 1 : 1)) * 100}%
                                                    <span>({stats.onlineRes.third}/{stats.onlineRes.thirdComp})</span>
                                                </td>
                                                :
                                                <td className='text-danger statsStyle'>
                                                    <MDBIcon className='me-1' fas icon='caret-down' />
                                                    {Math.round((1 - stats.onlineRes.third / stats.onlineRes.thirdComp) * 10) * 10}%
                                                    <span>({stats.onlineRes.third}/{stats.onlineRes.thirdComp})</span>
                                                </td>
                                        }
                                    </tr>
                                    <tr>
                                        <th scope='row'>Nowi pacjencji</th>
                                        {
                                            (stats.newRes.first / stats.newRes.firstComp - 1 >= 0) ?
                                                <td className='text-success statsStyle'>
                                                    <MDBIcon className='me-1' fas icon='caret-up' />
                                                    {Math.round(stats.newRes.first / (stats.newRes.first - 1 == 0 ? stats.newRes.first - 1 : 1)) * 100}%
                                                    <span>({stats.newRes.first}/{stats.newRes.firstComp})</span>
                                                </td>
                                                :
                                                <td className='text-danger statsStyle'>
                                                    <MDBIcon className='me-1' fas icon='caret-down' />
                                                    {Math.round((1 - stats.newRes.first / stats.newRes.firstComp) * 10) * 10}%
                                                    <span>({stats.newRes.first}/{stats.newRes.firstComp})</span>
                                                </td>
                                        }
                                        {
                                            (stats.newRes.second / stats.newRes.secondComp - 1 >= 0) ?
                                                <td className='text-success statsStyle'>
                                                    <MDBIcon className='me-1' fas icon='caret-up' />
                                                    {(stats.newRes.second / (stats.newRes.second - 1 == 0 ? stats.newRes.second - 1 : 1)) * 100}%
                                                    <span>({stats.newRes.second}/{stats.newRes.secondComp})</span>
                                                </td>
                                                :
                                                <td className='text-danger statsStyle'>
                                                    <MDBIcon className='me-1' fas icon='caret-down' />
                                                    {Math.round((1 - stats.newRes.second / stats.newRes.secondComp) * 10) * 10}%
                                                    <span>({stats.newRes.second}/{stats.newRes.secondComp})</span>
                                                </td>
                                        }
                                        {
                                            (stats.newRes.third / stats.newRes.thirdComp - 1 >= 0) ?
                                                <td className='text-success statsStyle'>
                                                    <MDBIcon className='me-1' fas icon='caret-up' />
                                                    {(stats.newRes.third / (stats.newRes.third - 1 == 0 ? stats.newRes.third - 1 : 1)) * 100}%
                                                    <span>({stats.newRes.third}/{stats.newRes.thirdComp})</span>
                                                </td>
                                                :
                                                <td className='text-danger statsStyle'>
                                                    <MDBIcon className='me-1' fas icon='caret-down' />
                                                    {Math.round((1 - stats.newRes.third / stats.newRes.thirdComp) * 10) * 10}%
                                                    <span>({stats.newRes.third}/{stats.newRes.thirdComp})</span>
                                                </td>
                                        }
                                    </tr>
                                </MDBTableBody>
                            </MDBTable>
                        </div>
                    </div>
                </MDBCardBody >
            </MDBCard >
        </>
    );
}
