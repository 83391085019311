import { useState, useEffect } from "react";
import Constants from "../../utilities/Constants";
import { ICalendarProps, ICalendarView, IContainerProps } from "../../global/Interfaces";
import CalendarView from "./CalendarView";
import axios, { AxiosResponse } from "axios";

export default function CalendarContainer(props: IContainerProps) {
    const [calendarData, setCalendarData] = useState<ICalendarProps[]>([]);
    const [forceUpdate, setForceUpdate] = useState<number>(0);
    const [selectedView, setSelectedView] = useState<ICalendarView>({
        month: 1,
        day: 0,
    });

    useEffect(() => {
        if (forceUpdate == 30 || forceUpdate == 90) {
            props.handleReservation(forceUpdate);
        } else {
            fetchData();
        }
    }, [forceUpdate]);

    useEffect(() => {
        fetchData();
    }, [props.duration, selectedView.month]);

    const fetchData = async () => {
        let currentDate = new Date();
        let sDate = new Date(currentDate.getFullYear() + `/` + (currentDate.getMonth() + 1) + `/01`);
        sDate.setMonth(sDate.getMonth() + selectedView.month - 1);
        sDate.setDate(sDate.getDate() - (sDate.getDay() == 0 ? 5 : sDate.getDay() - 2));
        
        const url = Constants.API_URL_GET_AVAILABLE;
        const data = {
            date: sDate.toISOString().split('T')[0],
            period: 41,
            duration: props.duration,
        }
        axios
            .get<ICalendarProps[]>(url + `?Date=${data.date}&Period=${data.period}&Duration=${data.duration}`)
            .then((response: AxiosResponse) => {
                
                let availableMonth = false;
                let resonseData = Array(response.data)[0];
                let currentMonth = new Date(resonseData[20].Date).getMonth() + 1;

                for(let i = resonseData.length - 1; i > 0; i--) {
                    let selectedMonth = new Date(resonseData[i].Date).getMonth() + 1;
                    if(selectedMonth == currentMonth && resonseData[i].Availables != 0) {
                        availableMonth = true;
                        break;
                    }
                }

                if(availableMonth) {
                    setCalendarData(resonseData);
                }
                else {
                    setSelectedView({
                        month: selectedView.month++,
                        day: selectedView.day,
                    });
                }
            })
            .catch(function (error: any) {
                console.log(error);
            });
    }
    return (
        <div>
            {calendarData.length != 0 && 
                <CalendarView
                    CalendarData={calendarData}
                    selectedDuration={props.duration}
                    calendarView={selectedView}
                    handleView={setSelectedView}
                    forceUpdate={setForceUpdate}
                />}
        </div>
    );
}
