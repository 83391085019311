import { MDBBtn } from "mdb-react-ui-kit";
import { useState, useEffect } from "react";
import { getMonthDiff } from "../../global/Functions";
import { IModalData } from "../../global/Interfaces";
import CalendarBody from "./CalendarBody";
import ReservationModal from "./ReservationModal";
import TermList from "./TermList";
import { ICalendarProps, ICalendarViewProps, IDetails } from "../../global/admin/Interfaces";
import InfoModal from "./InfoModal";

const findDefault = (data: ICalendarProps[], selectedDay: number) => {
    let date = new Date(new Date(data[8].Date).getFullYear(), new Date(data[8].Date).getMonth(), 1);
    let dayNumber = date.getDay() == 0 ? 6 : date.getDay() - 1;

    if (selectedDay > 0) {
        let dataToReturn = data[selectedDay + dayNumber - 1];

        if (dataToReturn.Terms.length) return dataToReturn;
    }

    let todayDate = new Date();
    let currentDate = date.getDate() + dayNumber;
    if (date.getMonth() == todayDate.getMonth() && date.getFullYear() == todayDate.getFullYear()) {
        currentDate = new Date().getDate() + dayNumber;
    }
    for (let i = -1; i < 8; i++) {
        if (data[currentDate + i].Terms.length) return data[currentDate + i];
    }
    return data[8];
};

export default function CalendarView(props: ICalendarViewProps) {
    const monthsName = ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];
    const currentView = new Date(props.CalendarData[20].Date);
    const [data, setData] = useState<ICalendarProps[]>(props.CalendarData);
    const [currentPosition, setCurrentPosition] = useState<number>(() => {
        return currentView.getMonth() - new Date().getMonth();
    });
    const [currentDate, setCurrentDate] = useState<ICalendarProps>(() => findDefault(props.CalendarData, props.calendarView.day));
    const [currentTime, setCurrentTime] = useState<string>("00:00:00");
    const [modalStatus, setModalStatus] = useState<boolean>(false);

    const [infoModal, setInfoModal] = useState<IDetails>({
        Id: "",
        PatientId: "",
        BookerName: "",
        BookerSurname: "",
        Name: "",
        Surname: "",
        Email: "",
        Phone: "",
        Date: "",
        Time: "",
        Duration: 30,
        IsBookingMyself: 0,
        IsUserBooked: 0,
        IsConfirmed: 0,
        IsCanceled: 0,
        DateCreated: new Date(),
        DateConfirmed: new Date(),
        DateCanceled: new Date(),
    });
    const [infoModalStatus, setInfoModalStatus] = useState<boolean>(false);

    const [buttonStatus, setButtonStatus] = useState<boolean>(false);
    let modalData: IModalData = {
        date: currentDate.Date,
        time: currentTime,
        duration: props.selectedDuration
    }

    const handlePosition = (count: number) => {
        if (count == 0) {
            setCurrentPosition(0);
        } else if (count == 1) {
            setCurrentPosition(currentPosition + 1);
        } else if (count == -1) {
            setCurrentPosition(currentPosition - 1);
        }
        setCurrentTime("00:00:00");
        setButtonStatus(false);
    };

    useEffect(() => {
        setData(props.CalendarData);

        let sameMonth = new Date(props.CalendarData[8].Date).getMonth() == new Date(currentDate.Date).getMonth();
        let tempData = findDefault(props.CalendarData, sameMonth ? new Date(currentDate.Date).getDate() : 0);
        setCurrentDate(tempData);

        if (!tempData.Terms.filter(x => x.Time == currentTime).length) {
            setCurrentTime("00:00:00");
            setButtonStatus(false);
        }
    }, [props.CalendarData]);

    useEffect(() => {
        props.handleView({
            month: currentPosition + 1,
            day: new Date(currentDate.Date).getDate(),
        });
    }, [currentPosition])


    const handleInfoModal = (data: any) => {
        setInfoModal(data);
        setInfoModalStatus(true);
    }

    useEffect(() => {
        modalData.time = currentTime;
        if (currentTime != "00:00:00") setButtonStatus(true);
    }, [currentTime]);

    useEffect(() => {
        if (currentDate.Availables == 0) setButtonStatus(false);
        else if (currentTime == "00:00:00" ||
            currentDate.Terms.filter(entry => entry.Time == currentTime).length == 0) {
            // setCurrentTime(currentDate.Terms[0].Time);
            setCurrentTime("00:00:00");
        }

        if (getMonthDiff(new Date(), new Date(currentDate.Date)) <= 1 &&
            new Date(currentDate.Date).getMonth() != currentView.getMonth()) {
            handlePosition(getMonthDiff(currentView, new Date(currentDate.Date)));
        }
    }, [currentDate]);

    return (
        <>
            {modalData.time != "00:00:00" &&
                <ReservationModal
                    isActive={modalStatus}
                    handleModal={() => setModalStatus(false)}
                    forceUpdate={(e: any) => { 
                        setCurrentTime("00:00:00");
                        setModalStatus(false);
                        props.forceUpdate(e);                        
                    }}
                    data={modalData}
                />}

            <InfoModal
                isActive={infoModalStatus}
                handleModal={() => setInfoModalStatus(false)}
                forceUpdate={props.forceUpdate}
                data={infoModal}
            />

            <div className="app-container row">
                <div className="datepicker col-md-6">
                    <div className="datepicker-calendar">
                        <div className="calendar-header">
                            <div className="goback" onClick={() => handlePosition(-1)}>
                                <svg width="30" height="30">
                                    <path fill="none" stroke="#0DAD83" strokeWidth="3" d="M19,6 l-9,9 l9,9" />
                                </svg>
                            </div>
                            <div className="current-month-container" onClick={() => handlePosition(0)}>{currentView.getFullYear()} {monthsName[currentView.getMonth()]}</div>
                            <div className="goforward" onClick={() => handlePosition(1)}>
                                <svg width="30" height="30">
                                    <path fill="none" stroke="#0DAD83" strokeWidth="3" d="M11,6 l9,9 l-9,9" />
                                </svg>
                            </div>
                        </div>

                        {data.length != 0 && <CalendarBody
                            CalendarData={data}
                            selectedDate={currentDate.Date}
                            handleList={setCurrentDate}
                        />}

                    </div>
                    <div style={{ textAlign: "center" }}>
                        <MDBBtn
                            style={{ marginTop: "20px" }}
                            color="success"
                            className="col-9"
                            disabled={!buttonStatus}
                            onClick={() => setModalStatus(true)}
                        >Rezerwuj
                        </MDBBtn>
                    </div>
                </div>
                <div className="timepicker col-md-6">
                    <div className="timepicker-container-outer">
                        <div className="timepicker-container-inner">
                            {currentDate.Terms.length ? (
                                <TermList
                                    CalendarData={currentDate}
                                    selectedTime={modalData.time}
                                    handleTime={setCurrentTime}
                                    handleDisplay={handleInfoModal}
                                />
                            ) : (
                                <div className="row" style={{ textAlign: "center", marginTop: "50px" }}>
                                    <h4>Brak dostępnych terminów.</h4>
                                </div>
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}
