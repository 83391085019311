import '../assets/styles/calendar.css';
import { MDBCard, MDBCardHeader, MDBCardBody } from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';
import CalendarContainer from '../components/calendar/CalendarContainer';
import { useLocation } from 'react-router-dom';
import Constants from '../utilities/Constants';
import axios, { AxiosResponse } from 'axios';
import { ISystemStatus } from '../global/Interfaces';
import { Helmet } from 'react-helmet-async';

const CancelMsg = (selecteId: string) => {
    const [displayText, setDisplayText] = useState<any>(<></>);

    useEffect(() => {
        const url = Constants.API_URL_GET_CANCEL;
        axios
            .get(url + `?Id=${selecteId}`)
            .then((response: AxiosResponse) => {
                setDisplayText(<><strong><h3>Rezerwacja została anulowana!</h3></strong></>);
            })
            .catch(function (error: any) {
                if (error.response.data.errors && error.response.data.errors.Reservation[0] == "Selected reservation already has been canceled.") {
                    setDisplayText(<><strong><h3>Rezerwacja został już anulowana.</h3></strong></>);
                } else {
                    setDisplayText({})
                    setDisplayText(<><strong><h3>Coś poszło nie tak, nie mamy takiej wizyty w bazie.</h3></strong><h4>Sprawdź czy wszedłeś w odpowiedni link!</h4></>);
                }
            });
    }, []);

    return (
        <center>
            {displayText}
        </center>
    );
};

const ConfirmMsg = (selecteId: string) => {
    const [displayText, setDisplayText] = useState<any>(<></>);

    useEffect(() => {
        const url = Constants.API_URL_GET_CONFIRM;
        axios
            .get(url + `?Id=${selecteId}`)
            .then((response: AxiosResponse) => {
                setDisplayText(<><strong><h3>Rezerwacja została potwierdzona!</h3></strong><h4>Potwierdzenie rezerwacji zostało wysłane również na maila.</h4></>);
            })
            .catch(function (error: any) {
                if (error.response.data.errors && error.response.data.errors.Reservation[0] == "Selected reservation already has been canceled.") {
                    setDisplayText(<><strong><h3>Rezerwacja został już anulowana.</h3></strong></>);
                } else if (error.response.data.errors && error.response.data.errors.Reservation[0] == "Selected reservation already has been confirmed.") {
                    setDisplayText(<><strong><h3>Rezerwacja została już potwierdzona.</h3></strong></>);
                } else if (error.response.data.errors && error.response.data.errors.Reservation[0] == "The selected booking date is no longer available.") {
                    setDisplayText(<><strong><h3>Wybrany termin rezerwacji jest już zajęty!</h3></strong></>);
                } else {
                    setDisplayText({})
                    setDisplayText(<><strong><h3>Coś poszło nie tak, nie mamy takiej wizyty w bazie.</h3></strong><h4>Sprawdź czy wszedłeś w odpowiedni link!</h4></>);
                }
            });
    }, []);

    return (
        <center>
            {displayText}
        </center>
    );
};

const DisplayReservationMsg = () => {
    return (
        <center>
            <strong><h3><p>Dziękujemy za złożenie rezerwacji.</p></h3></strong>
            <h4>
                <p>Na podany adres email została wysłana wiadomość z <span style={{ color: "#FBBC05" }}>prośbą o potwierdzenie wizyty.</span></p>
                <p>Prosimy o potwierdzenie, gdyż do tego czasu wybrany termin nie jest zarezerowany!</p>
            </h4>
            <h4><p>Prosimy sprawdzić folder <span style={{ color: "#EA4335" }}>SPAMu</span> lub <span style={{ color: "#EA4335" }}>POWIADOMIEŃ</span></p></h4>
        </center >
    );
};

const DisplayNotAvailableMsg = () => {
    return (
        <div className='errorMsg'>
            <center>
                <strong><h2><p>Rezerwacje online są aktualnie niedostępne!</p></h2></strong>
                <h4>
                    <p>Prosimy spróbować później lub umówić wizytę się telefonicznie:
                        <strong><a href="tel:662363848" style={{ color: "black", textDecoration: "none", fontSize: "22px" }}> 25 644 03 03</a></strong></p>
                </h4>
            </center >
        </div>
    );
};

const ReservationCard = (props: { configuration: ISystemStatus }) => {
    const [displayStatus, setDisplayStatus] = useState<number>(0);
    const [duration, setDuration] = useState<number>(30);
    const [status] = useState<ISystemStatus>(props.configuration);
    const location = useLocation();
    window.history.replaceState({}, document.title);

    if (displayStatus == 30 || displayStatus == 90) {
        return DisplayReservationMsg();
    } else if (location.state && location.state.params != -1 && location.state.request == "cancel") {
        return CancelMsg(location.state.params);
    } else if (location.state && location.state.params != -1 && location.state.request == "confirm") {
        return ConfirmMsg(location.state.params);
    } else {
        return (
            <>
                <div className="row">
                    <h3>Rodzaj wizyty:</h3>
                </div>
                <div className="row" id='durationSection'>
                    <div role="radiogroup" className="radio-group" aria-labelledby="group_heading">
                        <input type="radio" id='durationLong' name='durationSelect' onClick={() => setDuration(90)} />
                        <label className="radio-label" htmlFor='durationLong'>
                            <h3>Pierwsza wizyta</h3>
                            <p>Wizyta trwa do {status.longTime} minut i kosztuje {status.longPrice}zł.</p>
                        </label>
                        <input type="radio" id='durationShort' name='durationSelect' onClick={() => setDuration(30)} defaultChecked />
                        <label className="radio-label" htmlFor='durationShort'>
                            <h3>Kolejna wizyta</h3>
                            <p>Wizyta trwa do {status.shortTime} minut i kosztuje {status.shortPrice}zł.</p>
                        </label>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <h3>Termin wizyty:</h3>
                </div>
                <CalendarContainer duration={duration} handleReservation={setDisplayStatus} />
            </>
        );
    }
}

export default function RezerwacjaWizyty() {
    const defoultStatus = {
        isReservationOn: 0,
        longPrice: 0,
        longTime: 0,
        shortPrice: 0,
        shortTime: 0,
    }
    const [status, setStatus] = useState<ISystemStatus>(defoultStatus);

    useEffect(() => {
        axios
            .get<ISystemStatus>(Constants.API_URL_GET_SYSTEM_STATUS)
            .then((response: AxiosResponse) => {
                setStatus(response.data);
            })
            .catch(function (error: any) {
                setStatus(defoultStatus);
            });
    }, []);

    return (
        <>
            <Helmet>
                <title>Rezerwacja wizyty</title>
                <meta name="title" content="Rezerwuj Wizytę Online w Homeopatycznym Gabinecie Lekarskim" />
                <meta name="description" content="Wygodna rezerwacja wizyt online. Nazywam się Małgorzata Grabowiec, jestem lekarzem medycyny i prowadzę Homeopatyczny Gabinet Lekarski w Siedlcach. Umów się online na spotkanie w Homeopatycznym Gabinecie Lekarskim w Siedlcach." />
                <meta name="keywords" content="rezerwacja wizyty online, umów wizytę, homeopatyczny gabinet lekarski, Małgorzata Grabowiec, homeopatia, zdrowie, Siedlce, lekarz homeopata" />
                <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <MDBCard>
                <MDBCardHeader>Rezerwacja wizyty lekarskiej</MDBCardHeader>
                <MDBCardBody>
                    {status.isReservationOn == 1 && <ReservationCard configuration={status} />}
                    {status.isReservationOn == 0 && <DisplayNotAvailableMsg />}
                </MDBCardBody>
            </MDBCard>
        </>
    );
}
