export const casesData = [
    {
        title: "Autyzm dziecięcy",
        image: "autyzm-dzieciecy.jpg",
        alt: "Leczenie homeopatyczne autyzm dzieciecy. Lekarz Siedlce.",
        text: <>
            <p><strong>Chłopczyk lat 5</strong>, zdiagnozowany <strong>autyzm dziecięcy</strong>.
                Rodzice pacjenta zgłosili się z synem z powodu nawracających zapaleń oskrzeli i płuc, których długie leczenie antybiotykami i lekami sterydowymi wziewnymi skutkowało tym, że chłopiec był  bardziej ociężały, jego waga rosła,
                a rodzice stawali się bezradni wobec nowych przeciwności.</p>
            <p>Z wywiadu z opiekunami pacjenta uzyskałam następujące informacje:</p>
            <ul>
                <li>stopniowe wycofanie się chłopca zauważyli, gdy miał półtora roku,</li>
                <li>nasilenie negatywnych zachowań takich jak: złość, bicie siebie pięściami i kogoś bez powodu, brak kontaktu wzrokowego i silny upór,</li>
                <li>reagowanie  na zakazy histerią, którą trudno było opanować (rzucanie się na podłogę),</li>
                <li>nie następował rozwój mowy (brak nowych słów), zapominanie używanych wcześniej,</li>
                <li>po postawieniu u chłopca diagnozy -  <strong>autyzm dziecięcy</strong>, rozpoczęto terapię wczesnego wspomagania,
                    ale kontakt i współpraca terapeutów  z dzieckiem była bardzo ograniczona, a  efekty niezauważalne.</li>
            </ul>
            <p>Po zebraniu <strong>szczegółowego wywiadu</strong>, łącznie z rodzinnym, rozpoczęłam leczenie <strong>lekami homeopatycznymi</strong>.
                Chłopiec jest pod moją opieką od półtora roku. Zaobserwowano postępy jego ogólnego rozwoju na wielu płaszczyznach.
                Obecnie, to chłopiec:</p>
            <ul>
                <li>kontaktowy, bardzo dobrze współpracujący z terapeutami,</li>
                <li>jest lubiany, ma kolegów,</li>
                <li>coraz bardziej samodzielny, rozumie i właściwie reaguje na zakazy,</li>
                <li>chętne się uczy i zapamiętuje wiadomości,</li>
                <li>występuje przed grupą,</li>
                <li>jest radosny, pogodny,</li>
                <li>rozmawia i coraz wyraźniej mówi.</li>
            </ul>
            <p>Pacjent pozostaje pod moją opieką. Rodzicom udało się obniżyć <strong>wagę syna</strong>. Chłopiec jest zadowolony (może szybciej biegać).
                Przebyte ostatnio niedyspozycje, to pojawienie się dwa razy lekkiego kataru.</p>
        </>,
    },
    {
        title: "Borelioza",
        image: "leczenie-borelioza.jpg",
        alt: "Leczenie homeopatyczne boreliozy. Lekarz Siedlce.",
        text: <>
            <p><strong>Pacjentka lat 40</strong> zgłosiła się z powodu <strong>dolegliwości bólowych w stawach</strong>. </p>
            <p>Z wywiadu z pacjentką uzyskałam następujące informacje:</p>
            <ul>
                <li>pacjentka cierpi na bóle migrujące (przemieszczające się z jednego miejsca w drugie), </li>
                <li>momentami bóle bardzo nasilone, uniemożliwiające ruch,</li>
                <li>powiązano występujące dolegliwości z ugryzieniem przez kleszcza 2 miesiące wcześniej,</li>
                <li>charakterystyczny rumień, w niewielkim stopniu utrzymuje się do dnia wizyty, </li>
                <li>w miesiącu poprzedzającym wizytę w moim gabinecie zastosowano antybiotykoterapię, która zmniejszyła ból w miejscu ugryzienia,</li>
                <li>wykonano badania immunologiczne, które potwierdziły podejrzenia zakarzenia: w klasie Ig M 46,05 i Ig G 5,65.</li>
            </ul>
            <p>Po zebranym wywiadzie włączyłam <strong>leczenie homeopatyczne</strong>. Po czterech miesiącach od wprowadzenia leczenia wykonano kolejne badania, których wyniki potwierdziły jego skuteczność:</p>
            <ul>
                <li>IgG 5,60, IgM 37,18 - nastąpił spadek miana ostrej fazy,</li>
                <li>pacjentka sporadycznie odczuwała dolegliwości bólowe o zdecydowanie mniejszym nasileniu. </li>
            </ul>
            <p>Pozostaje pod moją opieką. Kolejne badanie zaplanowano za pół roku.</p>
        </>,
    },
    {
        title: "Lęk przedszkolny",
        image: "lek-przedszkolny.jpg",
        alt: "Leczenie leku u malych dzieci. Lekarz Siedlce.",
        text: <>
            <p>Na wizytę zgłosiła się mama, która nie mogła ustalić przyczyny <strong>niechęci i lęku</strong> syna do pozostania z grupą dzieci
                w przedszkolu. Pierwsza próba pozostawienia dziecka w przedszkolu spowodowała wręcz atak paniki i chęć ucieczki. Kolejne dni, a nawet tygodnie nie przynosiły oczekiwanej zmiany zachowania.</p>
            <p>Podczas wywiadu z mamą pacjenta przeanalizowałam trudne rodzinne sytuacje z przed trzech lat: </p>
            <ul>
                <li>mama dziecka była wtedy w 7 miesiącu ciąży, </li>
                <li>jej matka (babcia pacjenta znajdowała się w stanie krytycznym "na łożu śmierci"),</li>
                <li>ogromna <strong>rodzinna trauma</strong> ( babcia pragnęła zobaczyć długo wyczekiwanego wnuka). </li>
                <li>zastosowane u babci leczenie homeopatyczne umożliwiło spotkanie z ukochanym  nowonarodzonym wnukiem.</li>
            </ul>
            <p>Po zebranym wywiadzie włączyłam u trzylatka <strong>leczenie homeopatyczne</strong>. Chłopczyk w ciągu kilku dni zaczął chętnie uczęszczać do przedszkola i zostawać z dziećmi.</p>
        </>,
    },
    {
        title: "Zapalenia zatok",
        image: "zapalenie-zatok.jpg",
        alt: "Leczenie zapalenia zatok homeopatycznie. Lekarz Siedlce.",
        text: <>
            <p><strong>Duża grupa pacjentów</strong> zgłasza się z problemem, którym jest przewlekłe cierpienie z powodu spływającej wydzieliny po tylnej ścianie gardła. </p>
            <p>Z przeprowadzonych wywiadów wynika, że u  chorych okresowo wywołuje to:</p>
            <ul>
                <li>kaszel, </li>
                <li>chrząkanie uciążliwe dla nich samych jak i dla otoczenia.</li>
            </ul>
            <p>Leczenie przebiega etapowo:</p>
            <ol>
                <li>Podstawą zastosowania odpowiedniej, skutecznej terapii przyczynowej jest badanie wykluczające nosicielstwo patogennych bakterii lub grzybów. </li>
                <li>Po otrzymaniu wyniku mogłam zastosować leczenie homeopatyczne, w celu pozbycia się przyczyny przewlekłego zapalenia. <strong>Homeopatia ma takie możliwości</strong>. </li>
                <li>Skuteczność terapii potwierdziłam badaniem kontrolnym. </li>
                <li>Następnie podałam kolejne leki homeopatyczne, w celu likwidacji skutków długotrwałego stanu zapalnego.</li>
            </ol>
        </>,
    },
    {
        title: "Alergie",
        image: "alergie-alergia.jpg",
        alt: "Leczenie alergii metodami homeopatycznymi. Lekarz Siedlce.",
        text: <>
            <p>W obecnych czasach dzieci jak i dorośli borykają się z licznymi <strong>nietolerancjami pokarmowymi</strong>:</p>
            <ul>
                <li>dolegliwości te kumulują się głównie w <strong>przewodzie pokarmowym</strong>, ale również na zewnątrz  w postaci  zmian skórnych. Skóra w/g <strong>terapii holistycznej</strong> jest lusterkiem przewodu pokarmowego. Wiedzą to pacjenci cierpiący na alergie pokarmowe. </li>
            </ul>
            <p>Kolejne to <strong>alergie wziewne</strong>, które pojawiają się okresowo: </p>
            <ul>
                <li>wynikają z pylenia traw, drzew, zbóż w różnym czasie na przestrzeni roku. </li>
            </ul>
            <p>Wpływ na pojawianie się określonych objawów alergii mają również pory roku: </p>
            <ul>
                <li>osoby nadwrażliwe na wilgoć mają problem w okresie jesienno-zimowym z powodu wilgoci oraz grzybów pleśniowych, </li>
                <li>zimą świat roślin zamiera, ale długie przebywanie w pomieszczeniach powoduje, że kurz i roztocza kurzu domowego stają się czynnikiem silnie alergizującym. Kurz jest wszechobecny i wręcz niemożliwe jest z nim wygrać.</li>
            </ul>
            <p><strong>Homeopatia jest w stanie skutecznie zmniejszyć negatywny wpływ</strong>, a dłuższym czasie wręcz sprowadzić do minimum nad reaktywność.</p>
        </>,
    },
    {
        title: "Narkolepsja",
        image: "narkolepsja.jpg",
        alt: "Homeopatyczne leczenie narkolepsji. Lekarz Siedlce.",
        text: <>
            <p><strong>Dziewczyna lat 14, narkolepsja</strong> zdiagnozowana w czasie pobytu w Instytucie Neurologii. Rodzicom początkowo trudno było zaakceptować uzyskaną informację na temat zdrowia córki. </p>
            <p>Z wywiadu z mamą pacjentki wynika że: </p>
            <ul>
                <li>dziewczynka "od zawsze" dużo spała - jako niemowlę i przedszkolak,</li>
                <li>ostatnie lata stały się bardzo uciążliwe (zasypiała kilkanaście razy w ciągu dnia),</li>
                <li>została skierowana do szpitala w celu postawienia diagnozy. </li>
            </ul>
            <p>Po postawieniu diagnozy lekarze poinformowali rodziców nastolatki, o tym, że przyczyna tej jednostki chorobowej nie jest znana oraz obecnie nie mogą zaproponować odpowiedniego, skutecznego leczenia.</p>
            <p>Po zebraniu szczegółowego wywiadu z rodzicami i pacjentką otrzymała ona <strong>lek homeopatyczny</strong>. </p>
            <p>Początkowo rodzice dziewczynki zauważyli, że:</p>
            <ul>
                <li>podczas odrabiania lekcji nie zasypia tak często, co do tej pory było nagminne (trzeba było obok niej być i co chwilę ją budzić, aby cokolwiek zrobiła, w klasie pilnowały ją koleżanki),</li>
                <li>po leku homeopatycznym stała się bardziej ożywiona, ma więcej chęci do życia, działanie, </li>
                <li>wykazuje mniej złych emocji i złości np. w stosunku do młodszego rodzeństwa</li>
                <li>ustąpiła senność podczas lekcji,</li>
                <li>chętniej pomaga w obowiązkach domowych (sama wie co trzeba zrobić).</li>
            </ul>
            <p>Z czasem nastąpiła  zmiana na wielu płaszczyznach: </p>
            <ul>
                <li>nauka sprawia jej przyjemność i się stara (zaczęło jej zależeć). </li>
            </ul>
            <p>W ciągu roku, od kiedy dziewczynka jest moją pacjentką nastąpił ogromny progres w jej <strong>ogólnym funkcjonowaniu</strong>. Przez cały czas jestem w kontakcie z rodzicami.</p>
        </>,
    },
    {
        title: "Kurzajki",
        image: "kurzajki.jpg",
        alt: "Leczenie homeopatycznie kurzajek. Lekarz Siedlce.",
        text: <>
            <p><strong>Panienka lat 17, kurzajki</strong> na dłoniach. </p>
            <p>Z przeprowadzonych wywiadów wynika, że chora:</p>
            <ul>
                <li>była z różnym skutkiem leczona przez dermatologów, </li>
                <li>okresowo zmieniano leki, ale nie przynosiły oczekiwanego efektu,</li>
                <li>znany na dłoniach są liczne i rozsiane, </li>
                <li>bardzo się ich wstydzi, </li>
                <li>pomimo tego, że są niebolesne, bardzo jej przeszkadzają.</li>
            </ul>
            <p>Po podaniu <strong>odpowiednich leków homeopatycznych</strong> kurzajki zniknęły bezpowrotnie. Od zastosowanego leczenia homeopatycznego  minęły dwa lata. </p>
            <p>Pacjentka  przebywa pod obserwacją.</p>
        </>
    },
    {
        title: "Zmiany skórne",
        image: "zmiany-skorne.jpg",
        alt: "Zmiany skorne leczenie homeopatyczne. Lekarz Siedlce.",
        text: <>
            <p><strong>Mężczyzna lat 42</strong>, problemy <strong>skórne okolic intymnych</strong>.</p>
            <p>Z wywiadu z pacjentem uzyskałam następujące informacje:</p>
            <ul>
                <li>leczony przez ponad rok przez profesora dermatologii (niestety bez efektu),</li>
                <li>zmiany nasilały się, a kolejne maści i środki pogarszały nie tylko stan skóry, ale i psychiczny pacjenta. </li>
            </ul>
            <p>Po przeprowadzonym wywiadzie zastosowałam leczenie etapowe poprzedzone odpowiednimi badaniami: </p>
            <ol>
                <li>Na moje zlecenie pacjent wykonał wymaz oraz <strong>badanie mykologiczne</strong>. </li>
                <li>Wyniki potwierdziły moje przypuszczenia - przyczyną choroby była <strong>Candida albicans oraz liczne bakterie</strong> (nikt wcześniej nie wykonał takiego badania). </li>
                <li>Po włączeniu <strong>leków homeopatycznych</strong> - przyczynowych, i dokonania przez pacjenta zmian w diecie, uzyskano stopniową poprawę, do całkowitej eliminacji zmian skórnych. </li>
                <li>Zmiany bliznowate po licznych, źle dobranych albo wręcz niepotrzebnych maściach, to jedyny problemem, z którym borykaliśmy się  dłużej, ale jest juz poza nami.</li>
            </ol>
            <p>Pacjent jest w <strong>pełni zdrowy</strong>, ale znajduje się pod moją opieką do dziś.</p>

        </>
    },
    {
        title: "Autyzm dziecięcy",
        image: "dzieciecy-autyzm.jpg",
        alt: "Autyzm dzieciecy terapia homeopatyczna.",
        text: <>
            <p><strong>Dziewczynka lat 4</strong>, zdiagnozowany <strong>autyzm wczesnodziecięcy</strong>. </p>
            <p>Z wywiadu z rodzicami pacjentki uzyskałam następujące informacje o dziecku: </p>
            <ul>
                <li>dość pogodna,</li>
                <li>lubi tańczyć,</li>
                <li>mówi pojedyncze słowa, bardzo niewyraźnie; tylko rodzice rozumieją komunikaty, </li>
                <li>jest wstydliwa, wycofana, nie utrzymująca kontaktu wzrokowego,  </li>
                <li>w czasie wykonywania zadań - brak koncentracji, skupienia, nadruchliwa,</li>
                <li>hałas, burza, ciemność wzbudzają w niej liczne lęki, </li>
                <li>bardzo uparta i nieprzewidywalna - nagły krzyk pojawiał się w różnych miejscach jej nieznanych. </li>
            </ul>
            <p>Dziewczynka od kilku miesięcy poprzedzających wizytę u mnie uczestniczyła w zajęciach  terapeutycznych.
                Po zebranym wywiadzie podałam dziewczynce <strong>leki homeopatyczne mające</strong> na celu <strong>oczyszczanie organizmu</strong>,
                <strong>eliminację candida albicans i bakterii</strong>, które stwierdziłam w trakcie wykonanych badań.
            </p>
            <p>Stopniowo obserwowaliśmy poprawę w funkcjonowaniu dziecka:</p>
            <ul>
                <li>coraz lepszy kontakt z osobami sprawującymi  opiekę nad nim,</li>
                <li>potrafi dłużej skupić się podczas wykonywania zadań, </li>
                <li>jest radosna, </li>
                <li>chętnie bawi się z dziećmi i dorosłymi ( potrzebuje towarzystwa),</li>
            </ul>
            <p>Jej rozwój jest coraz bardziej harmonijny. Obecnie uczęszcza  do  szkoły i bardzo dobrze radzi sobie z obowiązkami ucznia. </p>
            <p>Jest ciekawą świata dziewczynką. Pozostaje pod moją opieką medyczną.</p>

        </>
    },
    {
        title: "Uraz głowy",
        image: "wypadek-motocyklowy-uraz-glowy.jpg",
        alt: "Leczenie urazow glowy po wypadkach.",
        text: <>
            <p><strong>Dziewczyna lat 18</strong>, uległa <strong>wypadkowi motocyklowemu</strong>. </p>
            <p>Makabryczną historię przedstawili mi rodzice dziewczyny:</p>
            <ul>
                <li>na zakręcie drogi motor wypadł z drogi,</li>
                <li>chłopiec kierujący motorem leżał nieprzytomny nieopodal, </li>
                <li>dziewczynę (córkę) wyrzuciło kilkanaście metrów dalej,</li>
                <li>badanie CT głowy wykazało, że we wszystkich komorach mózgu dziewczyny jest krew. </li>
            </ul>
            <p>Rodzicom nie pozostawiono nadziei określając  stan pacjentki jako skrajnie ciężki. Zdecydowaliśmy o <strong>podaniu leków homeopatycznych</strong>.</p>
            <p>Po  tygodniu leczenia:</p>
            <ul>
                <li>obraz CT mózgu ulegał poprawie - wywołało to zdziwienie i niedowierzanie</li>
            </ul>
            <p>Po kilkunastu dniach: </p>
            <ul>
                <li>odzyskała  przytomność, </li>
                <li>przewieziono pacjentkę na oddział neurologiczny, gdzie przeszła dalszą rehabilitację.</li>
            </ul>
            <p>Po opuszczeniu szpitala otrzymała kolejne <strong>leki homeopatyczne w celu  usunięcia luk w pamięci</strong>.
                Dziewczyna wróciła do zdrowia i za rok zdała maturę.
            </p>

        </>
    },
    {
        title: "Borelioza",
        image: "borelioza-kleszcz.jpg",
        alt: "Leczenie ukaszen przez kleczszcze - bolerioza",
        text: <>
            <p><strong>Pacjentka 51 lat</strong>, zgłosiła się <strong>z bólami kostno- mięśniowymi</strong>.</p>
            <p> Z wywiadu z pacjentką uzyskałam następujące informacje o stanie jej zdrowia:</p>
            <ul>
                <li>podwyższona temperatura w ciągu dnia, </li>
                <li>ogólne osłabienie, </li>
                <li>chęć leżenia,</li>
                <li>niechęć do pracy -    trudność wykonywania z powodu osłabienia siły mięśni,</li>
                <li>bóle migrujące i pojawiające się nagle. </li>
            </ul>
            <p>Pacjentka  po konsultacji u neurologa - zlecono badania w kierunku boreliozy:</p>
            <ul>
                <li>pierwsze wyniki wykazał, że w klasie IgM 17, 42;  w klasie Ig G 5,42. </li>
            </ul>
            <p>Kobiecie <strong>zaproponowano antybiotykoterapię</strong> stosowaną w leczeniu boreliozy. Pacjentka nie przypominała sobie jednak ugryzienia przez kleszcza
                i z obawy na reakcję alergiczną, którą wykazuje na wiele antybiotyków, nie przyjęła leków i zdecydowała się na <strong>podjęcie leczenia homeopatycznego</strong>. </p>
            <p>Włączyłam leczenie przyczynowe oraz objawowe. </p>
            <p>Dolegliwości stopniowo stały się mniej dokuczliwe: </p>
            <ul>
                <li>początkowo minęło zmęczenie,</li>
                <li>stopniowo zanikały bóle kostno- mięśniowe. </li>
            </ul>
            <p>Kolejne badania <strong>wykazały całkowite wyeliminowanie</strong> przyczyny dolegliwości. </p>
            <p>Nie stwierdzono obecności przeciwciał.</p>

        </>
    },
    {
        title: "Zapalenia oskrzeli",
        image: "zapalenie-oskrzeli.jpg",
        alt: "Terapia homeopatyczna zapalen oskrzeli.",
        text: <>
            <p>Rodzice zgłosili się z synem z powodu nawracających, <strong>spastycznych zapaleń oskrzeli z dusznościami</strong>.</p>
            <p>Z wywiadu z rodzicami pacjenta  uzyskałam następujące informacje o stanie jego zdrowia i prowadzonym leczeniu:</p>
            <ul>
                <li>leczony antybiotykami i lekami wziewnymi sterydowymi z różnym efektem,</li>
                <li>po wyleczonej  infekcji szybko następowała kolejna,</li>
                <li>z badań wykonanych przez alergologa - uczulenie na kurz i grzyby pleśniowe - bardzo duże nasilenie. </li>
            </ul>
            <p>Wyeliminowanie tych <strong>alergenów</strong> z życia nie jest możliwe, a stan zdrowia dziecka <strong>wymagał efektywnego leczenia</strong>:</p>
            <ul>
                <li>zostały włączone leki odpowiadające jego konstytucji,</li>
                <li>podano leki homeopatyczne w celu zmniejszenia nadreaktywności na kurz i grzyby pleśniowe. </li>
            </ul>
            <p>Podczas kolejnych  miesięcy obserwowano stopniową poprawę stanu zdrowia chłopca oraz  wyeliminowano
                z leczenia antybiotyki i leki wziewne sterydowe.
            </p>
            <p>Kolejny sezon jesienno - zimowy minął bardzo dobrze:</p>
            <ul>
                <li>pojawiające się katary, nie powodowały zaplenia oskrzeli. </li>
            </ul>
            <p>Wykonany przeze mnie dodatkowo wymaz z gardła, wykazał nosicielstwo bakterii, które zlikwidowałam  odpowiednimi <strong>lekami homeopatycznymi</strong>. </p>
            <p>Interesują mnie losy moich pacjentów i chociaż ówczesny siedmiolatek wyrósł na przystojnego, zdrowego mężczyznę do dziś pozostajemy w kontakcie.</p>

        </>
    },
    {
        title: "Niepokój niemowlęcia",
        image: "niepokoj-niemowlecia.jpg",
        alt: "Terapia niepokoju dzieciecego metodami homeopatycznymi.",
        text: <>
            <p>Z niemowlęciem zgłosili się rodzice z powodu ciągłego płaczu, niepokoju  dziecka  niemożliwego do opanowania .</p>
            <p>Zmęczeni trwającą od blisko 4 miesięcy sytuacją podejmowali różne próby, niestety mało skuteczne:</p>
            <ul>
                <li>bardzo intensywne, ciągłe bujanie wyciszało dziecko, ale odłożenie do łóżeczka ponownie powodowało płacz,</li>
                <li>zmieniono mleko, ale dodatkowo pojawiły się zmiany skórne, </li>
                <li>podano preparat mlekozastępczy - zmiany skórne zmniejszyły się, ale płacz nie ustał.</li>
            </ul>
            <p>Dopiero <strong>wnikliwy wywiad z rodzicami</strong> i <strong>szukanie w przeszłości pozwoliły ustalić przyczyny</strong> zachowania dziewczynki: </p>
            <ul>
                <li>matka na początku ciąży przeżyła silny wstrząs - podczas wizyty u ginekologa dowiedziała się, że jest bardzo poważnie chora - ma raka,</li>
                <li>lekarz bez przeprowadzenia badań bezdyskusyjnie stwierdził, że życie dziecka jest zagrożone, </li>
                <li>proponował rozważyć zakończenie ciąży.</li>
            </ul>
            <p>Kilka kolejnych, nerwowych tygodni, to liczne konsultacje u specjalistów. Diagnoza okazała się błędna i donoszona ciąża zakończyła się szczęśliwym rozwiązanie.
                Jednak stan psychicznym w jakim była matka w czasie pierwszych miesięcy ciąży został przeniesiony na dziecko - lęk przed śmiercią i tu tkwiła przyczyna.</p>
            <p><strong>Podałam lek homeopatyczny przyczynowy</strong>.
                W ciągu dwóch dni dziecko zaczęło spać a z nim rodzice.
            </p>

        </>
    },
    {
        title: "Hipogonadyzm",
        image: "hipogonadyzm.jpg",
        alt: "Leczenie hipogonadyzmu męskiego homeopatyczne.",
        text: <>
            <p>Zaniepokojeni rodzice nastolatka zauważyli u syna <strong>niedorozwój narządów płciowych</strong>.</p>
            <p>Podczas wywiadu z rodzicami i obserwacji chłopca zauważyłam:</p>
            <ul>
                <li>bardzo wstydliwy, wycofany i skryty,</li>
                <li>wzrost poniżej normy.</li>
            </ul>
            <p>Po nieudanej  próbie  zbadania pacjenta, zleciłam wykonanie badań laboratoryjnych -  wykazały <strong>poziom testosteronu</strong> poniżej normy. </p>
            <p>Po analizie zebranych informacji włączyłam <strong>leczenie homeopatyczne</strong> i po pół roku nastąpiła znacząca poprawa.  </p>
            <p>Chłopak nadal przyjmuje leki i przebywa pod moją obserwacją.</p>

        </>
    },
    {
        title: "Cefalgia",
        image: "terapia-powypadkowa-cefalgia.jpg",
        alt: "Terapia homeopatyczna powypadkowa.",
        text: <>
            <p>Kobieta lat 45, powodem wizyty są silne bóle głowy. </p>
            <p>Podczas przeprowadzonego wywiadu poznałam prawdopodobne przyczyny powstałych dolegliwości oraz informacje o stanie zdrowia i prowadzonym leczeniu:</p>
            <ul>
                <li>pacjentka rok temu <strong>uległa wypadkowi samochodowemu</strong> -  zderzenie z Tirem, </li>
            </ul>
            <p>po którym pojawiły się:</p>
            <ul>
                <li>silne bóle głowy,</li>
                <li>ogólne osłabienie, </li>
                <li>drętwieniem rąk, </li>
                <li>zaburzeniami równowagi. </li>
            </ul>
            <p>Liczne wizyty u neurologa oraz ortopedów przynosiły niewielką poprawę:</p>
            <ul>
                <li>zniesienie bólu na czas działania leków przeciwbólowych.</li>
            </ul>
            <p>W badaniach stwierdzono liczne <strong>stłuczenia kręgosłupa</strong>, szczególnie odcinka szyjnego:</p>
            <ul>
                <li>długi czas zalecano kobiecie noszenie kołnierza ortopedycznego. </li>
            </ul>
            <p>Po analizie zebranych informacji włączyłam dwukierunkowe <strong>leczenie homeopatyczne</strong>: </p>
            <ul>
                <li>związane z wypadkiem (wycofały lęk przed jazdą autem, lęk przed mijającym ją samochodem) </li>
                <li>znoszące ból. </li>
            </ul>
            <p>Stopniowo następowała poprawa i <strong>stan psychofizyczny</strong> kobiety wrócił do równowagi. </p>

        </>
    }
];

