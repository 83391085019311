import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function AnulujRezerwacje() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const redirect = (params: string) => {
        navigate('/rezerwacja-wizyty', {
            state: {
                request: 'cancel',
                params: params
            }
        })
    };

    useEffect(() => {
        redirect(searchParams.get("") ?? "-1");
    });


    return (
      <></>
    );
}
