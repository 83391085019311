import { IListElementProps, ITermListProps } from "../../global/Interfaces";

const TermListElement = (props: IListElementProps): any => {
    let time = props.TermData.Time.slice(0, 5);
    if (time[0] == "0") time = time.slice(1);
    time += ` - ` + (Number(props.TermData.Time.slice(0, 2)) + (props.TermData.Duration == 30 ? 1 : 2)).toString() + `:00`;

    return (
        <div onClick={() => props.handleTime(props.TermData.Time)}
            style={{width:"130px"}}
            className={`listElement availableTime${props.isSelected ? ' activeBtn' : ''}`}
            key={props.TermData.Time}>{time}</div>
    );
}

export default function TermList(props: ITermListProps) {
    const data = props.CalendarData;
    const result: any = [];

    data.Terms.forEach(item => {
        if (item.IsOccupied == 0 && item.IsVisible == 1) {
            result.push(
                <TermListElement
                    key={item.Time}
                    TermData={item}
                    handleTime={props.handleTime}
                    isSelected={item.Time == (props.selectedTime == "00:00:00" ? data.Terms[0].Time : props.selectedTime)} />
            );
        }
    });
    return result;
}
