const API_BASE_URL = 'https://api.lekarzsiedlce.pl';

const ENDPOINTS = {
    GET_AVAILABLE: 'api/auth/AuthReservation/available',

    GET_PATIENT_CARD: 'api/auth/Patient/patientCard',
    GET_PATIENT_FIND: 'api/auth/Patient/find',

    GET_VALIDATE: 'api/auth/AuthReservation/validate',
    POST_RESERVATION: 'api/auth/AuthReservation',
    GET_RESERVATION: 'api/auth/AuthReservation',

    GET_BLOCK: 'api/auth/Patient/block',

    GET_CANCEL: 'api/auth/AuthReservation/cancel',
    GET_CONFIRM: 'api/auth/AuthReservation/confirm',

    POST_TIMEOFF: 'api/auth/TimeOff',
    GET_TIMEOFF: 'api/auth/TimeOff',

    GET_CONFIGURATION: 'api/auth/AuthReservation/configuration',
    PUT_CONFIGURATION: 'api/auth/AuthReservation/configuration',
    GET_STATISTICS: 'api/auth/AuthReservation/statistics',
}

const API = {
    API_URL_GET_AVAILABLE: `${API_BASE_URL}/${ENDPOINTS.GET_AVAILABLE}`,

    API_URL_GET_PATIENT_CARD: `${API_BASE_URL}/${ENDPOINTS.GET_PATIENT_CARD}`,
    API_URL_GET_PATIENT_FIND: `${API_BASE_URL}/${ENDPOINTS.GET_PATIENT_FIND}`,

    API_URL_GET_BLOCK: `${API_BASE_URL}/${ENDPOINTS.GET_BLOCK}`,

    API_URL_GET_VALIDATE: `${API_BASE_URL}/${ENDPOINTS.GET_VALIDATE}`,
    API_URL_POST_RESERVATION: `${API_BASE_URL}/${ENDPOINTS.POST_RESERVATION}`,
    API_URL_GET_RESERVATION: `${API_BASE_URL}/${ENDPOINTS.GET_RESERVATION}`,

    API_URL_GET_CANCEL: `${API_BASE_URL}/${ENDPOINTS.GET_CANCEL}`,
    API_URL_GET_CONFIRM: `${API_BASE_URL}/${ENDPOINTS.GET_CONFIRM}`,

    API_URL_POST_TIMEOFF: `${API_BASE_URL}/${ENDPOINTS.POST_TIMEOFF}`,
    API_URL_GET_TIMEOFF: `${API_BASE_URL}/${ENDPOINTS.GET_TIMEOFF}`,

    API_URL_GET_CONFIGURATION: `${API_BASE_URL}/${ENDPOINTS.GET_CONFIGURATION}`,
    API_URL_PUT_CONFIGURATION: `${API_BASE_URL}/${ENDPOINTS.PUT_CONFIGURATION}`,
    API_URL_GET_STATISTICS: `${API_BASE_URL}/${ENDPOINTS.GET_STATISTICS}`,
}

const Constants = API;
export default Constants;
