import { useState, useEffect } from "react";
import Constants from "../../utilities/AdminConstants";
import { ICalendarView } from "../../global/Interfaces";
import CalendarView from "./CalendarView";
import axios, { AxiosResponse } from "axios";
import { ITimeOffProps } from "../../global/admin/Interfaces";

export default function CalendarContainer() {
    const [fetchedData, setFetchedData] = useState<ITimeOffProps[]>([]);
    const [forceUpdate, setForceUpdate] = useState<number>(0);
    const [selectedView, setSelectedView] = useState<ICalendarView>({
        month: 1,
        day: 0,
    });

    useEffect(() => {
        fetchData();
    }, [forceUpdate]);

    useEffect(() => {
        fetchData();
    }, [selectedView.month]);

    const fetchData = async () => {
        let currentDate = new Date();
        let sDate = new Date(currentDate.getFullYear() + `/` + (currentDate.getMonth() + 1) + `/01`);
        sDate.setMonth(sDate.getMonth() + selectedView.month - 1);
        sDate.setDate(sDate.getDate() - (sDate.getDay() == 0 ? 5 : sDate.getDay() - 2));

        const url = Constants.API_URL_GET_TIMEOFF;
        const data = {
            date: sDate.toISOString().split('T')[0],
            period: 41,
        }
        axios
            .get<ITimeOffProps[]>(url + `?Date=${data.date}&Period=${data.period}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('user')}`,
                }
            })
            .then((response: AxiosResponse) => {
                setFetchedData(Array(response.data)[0]);
                
            })
            .catch(function (error: any) {
                console.log(error);
            });
    }
    return (
        <div>
            {fetchedData.length != 0 &&
                <CalendarView
                    CalendarData={fetchedData}
                    calendarView={selectedView}
                    handleView={setSelectedView}
                    forceUpdate={setForceUpdate}
                />}
        </div>
    );
}
