import { MDBBtn } from "mdb-react-ui-kit";
import { useState, useEffect } from "react";
import { getMonthDiff } from "../../global/Functions";
import { ICalendarProps, ICalendarViewProps, IModalData } from "../../global/Interfaces";
import CalendarBody from "./CalendarBody";
import ReservationModal from "./ReservationModal";
import TermList from "./TermList";

const findDefault = (data: ICalendarProps[], selectedDay: number) => {
    if (selectedDay > 0) {
        let date = new Date(new Date(data[8].Date).getFullYear(), new Date(data[8].Date).getMonth(), 1);
        let dayNumber = date.getDay() == 0 ? 6 : date.getDay() - 1;

        let dataToReturn = data[selectedDay + dayNumber - 1];

        if (dataToReturn.Availables > 0) return dataToReturn;
    }
    let month = new Date(data[8].Date).getMonth();
    let firstDay = new Date(data[8].Date);
    firstDay.setDate(1);

    let toReturn = data[firstDay.getDay()];
    data.slice().reverse().forEach(item => {
        if (item.Availables > 0 && new Date(item.Date).getMonth() >= month) {
            toReturn = item;
        }
    });
    return toReturn;
};

export default function CalendarView(props: ICalendarViewProps) {
    const monthsName = ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];
    const currentView = new Date(props.CalendarData[20].Date);
    const [data, setData] = useState<ICalendarProps[]>(props.CalendarData);
    const [currentPosition, setCurrentPosition] = useState<number>(() => {
        return currentView.getMonth() - new Date().getMonth();
    });
    const [currentDate, setCurrentDate] = useState<ICalendarProps>(() => findDefault(props.CalendarData, props.calendarView.day));
    const [currentTime, setCurrentTime] = useState<string>(currentDate.Availables > 0 ? currentDate.Terms[0].Time : "00:00:00");
    const [modalStatus, setModalStatus] = useState<boolean>(false);
    const [buttonStatus, setButtonStatus] = useState<boolean>(true);
    let modalData: IModalData = {
        date: currentDate.Date,
        time: currentTime,
        duration: props.selectedDuration
    }

    const handlePosition = (count: number) => {
        if (props.calendarView.month != currentPosition + 1) {

        }
        if (count == 0) {
            setCurrentPosition(0);
        } else if (count == 1 && currentPosition == 0) {
            setCurrentPosition(currentPosition + 1);
        } else if (count == -1 && currentPosition == 1) {
            setCurrentPosition(currentPosition - 1);
        }
    };

    useEffect(() => {
        setData(props.CalendarData);
        setCurrentDate(findDefault(props.CalendarData, new Date(currentDate.Date).getDate()));
    }, [props.CalendarData]);

    useEffect(() => {
        props.handleView({
            month: currentPosition + 1,
            day: new Date(currentDate.Date).getDate(),
        });
    }, [currentPosition])

    useEffect(() => {
        modalData.time = currentTime;
    }, [currentTime]);

    useEffect(() => {
        if (currentDate.Availables == 0) setButtonStatus(false);
        else if (currentTime == "00:00:00" ||
            currentDate.Terms.filter(entry => entry.Time == currentTime).length == 0) {
            setCurrentTime(currentDate.Terms[0].Time);
        }

        if (getMonthDiff(new Date(), new Date(currentDate.Date)) <= 1 &&
            new Date(currentDate.Date).getMonth() != currentView.getMonth()) {
            handlePosition(getMonthDiff(currentView, new Date(currentDate.Date)));
        }
    }, [currentDate]);

    return (
        <>
            <ReservationModal
                isActive={modalStatus}
                handleModal={() => setModalStatus(false)}
                forceUpdate={props.forceUpdate}
                data={modalData}
            />

            <div className="app-container row">
                <div className="datepicker col-md-6">
                    <div className="datepicker-calendar">
                        <div className="calendar-header">
                            <div className={(currentPosition == 0 ? "disabledBtn " : "") + "goback"} onClick={() => handlePosition(-1)}>
                                <svg width="30" height="30">
                                    <path fill="none" stroke="#0DAD83" strokeWidth="3" d="M19,6 l-9,9 l9,9" />
                                </svg>
                            </div>
                            <div className="current-month-container" onClick={() => handlePosition(0)}>{currentView.getFullYear()} {monthsName[currentView.getMonth()]}</div>
                            <div className={(currentPosition == 1 ? "disabledBtn " : "") + "goforward"} onClick={() => handlePosition(1)}>
                                <svg width="30" height="30">
                                    <path fill="none" stroke="#0DAD83" strokeWidth="3" d="M11,6 l9,9 l-9,9" />
                                </svg>
                            </div>
                        </div>

                        {data.length != 0 && <CalendarBody
                            CalendarData={data}
                            selectedDate={currentDate.Date}
                            handleList={setCurrentDate}
                        />}

                    </div>
                    
                </div>
                <div className="timepicker col-md-6">
                        <div className="timepicker-container-outer">
                            <div className="timepicker-container-inner">
                                {currentDate.Availables > 0 ? (
                                    <TermList
                                        CalendarData={currentDate}
                                        selectedTime={modalData.time}
                                        handleTime={setCurrentTime}
                                    />
                                ) : (
                                    <div><strong>Brak dostępnych terminów</strong></div>
                                )}
                            </div>
                        </div>
                        <div className="buttons-container">
                            <MDBBtn
                                color="success"
                                className="d-grid col-8 mx-auto"
                                disabled={!buttonStatus}
                                onClick={() => setModalStatus(true)}
                            >Rezerwuj</MDBBtn>
                        </div>
                    </div>
            </div>
        </>
    );
}
