import { ITimeOffListElementProps, ITimeOffListProps } from "../../global/admin/Interfaces";

const TermListElement = (props: ITimeOffListElementProps): any => {
    let time = props.TermData.Time.slice(0, 5);
    if (time[0] == "0") time = time.slice(1);
    time += ` - ` + (Number(props.TermData.Time.slice(0, 2)) + 1).toString() + props.TermData.Time.slice(2, 5);

    if (props.TermData.IsOccupied) {
        if (props.TermData.IsOccupied == 2) {
            return (
                <div
                    className="listElement reservedTime"
                    key={props.TermData.Time}>
                    <div className="row">
                        <div className="col-4">{time}</div>
                        <div className="col">Rezerwacja</div>
                    </div>
                </div>
            );
        }
        return (
            <div onClick={() => props.handleTime(props.TermData.Time)}
                className={`listElement blockedTimeOff${props.isSelected ? ' activeTimeBtn' : ''}`}
                key={props.TermData.Time}>
                <div className="row">
                    <div className="col-4">{time}</div>
                    <div className="col">Wolne</div>
                </div>
            </div>
        );
    } else {
        return (
            <div onClick={() => props.handleTime(props.TermData.Time)}
                className={`listElement availableTimeOff${props.isSelected ? ' activeTimeBtn' : ''}`}
                key={props.TermData.Time}>
                <div className="row">
                    <div className="col-4">{time}</div>
                    <div className="col"></div>
                </div>
            </div>
        );
    }
}

export default function TermList(props: ITimeOffListProps) {
    const data = props.CalendarData;
    const result: any = [];

    data.Times.forEach(item => {
        result.push(
            <TermListElement
                key={item.Time}
                TermData={item}
                handleTime={props.handleTime}
                isSelected={props.selectedTime.includes(item.Time)} />
        );
    });
    return result;
}
